import { deepsClone,isEmpty} from '../assets/js/utils'

const excel = {
  namespaced: true,
  state: () => ({
    plusTask:{},
  }),
  mutations: {
    plusItem(state, data = {}){
      // let data = {
      //   name:'',
      //   createdId:'',
      // }
      if(isEmpty(data)) return false;
      state.plusTask = deepsClone(data);
    },
  },
  actions: {

  },
  getters: {
    newTask(state){
      let task = state.plusTask;
      if(isEmpty(task)) return {}
      return task
    },
  }
}

export default  excel;

var isDownloadExcel = true
var timeNum = 30
export function plusDownloadExcelTask(data = {}){
  if(isDownloadExcel){
    isDownloadExcel = false
    var time = setInterval(()=>{
      timeNum = timeNum -1
      if(timeNum == 0){
        clearTimeout(time)
        isDownloadExcel = true
        timeNum = 30
      }
    },1000)
    try {
      this.$store.commit('excel/plusItem',data);
    }catch (e) {
      console.error(e);
    }
  }else{
    this.$message({
      message: `操作过于频繁，请于后${timeNum}秒后重试`,
      type: 'warning'
    });
  }
}
