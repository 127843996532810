<template>
  <div class="height-all flex flex-column ">
    <slot name="before"></slot>
    <div class="flex-1" id="tableHeightAuto">
      <slot name="table" v-bind="{height,heightText}"></slot>
    </div>
    <slot name="after"></slot>
  </div>
</template>

<script>
  export default {
    name: 'tableHeightAuto',
    data(){
      return {
        height:0,
        heightText:'0px',
      }
    },
    mounted(){
      this.resize();
      this.bindEventResize();
    },
    destroyed(){
      this.removeEventResize();
    },
    methods:{
      bindEventResize(){
        window.addEventListener('resize',this.resize);
      },
      removeEventResize(){
        window.removeEventListener('resize',this.resize);
      },
      resize(e){
        let box = document.getElementById('tableHeightAuto');
        let height = box.clientHeight;
        this.height = height;
        this.heightText = height + 'px';
      },
    }
  }
</script>

<style scoped>

</style>
