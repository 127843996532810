import { MICRO_SERVICE_PATH } from './url.base'

// api request url
export const URL = {
  // 腾讯地图接口
  tencentApi: {
    // 根据IP定位
    location: 'https://apis.map.qq.com/ws/location/v1/ip',
    // 逆地址解析
    inverseAddressResolution: 'https://apis.map.qq.com/ws/geocoder/v1/',
    // 关键字输入提示
    suggestion: 'https://apis.map.qq.com/ws/place/v1/suggestion',
    // 地点搜索
    siteSearch: 'https://apis.map.qq.com/ws/place/v1/search'
  },

  // 商家 运费模板模块
  supplierPostage: {
    getShipTime: MICRO_SERVICE_PATH.back + '/supplierPostage/getShipTime', // 删除商家运费模板
    deleteSupplierPostage: MICRO_SERVICE_PATH.back + '/supplierPostage/deleteSupplierPostage', // 删除商家运费模板
    details: MICRO_SERVICE_PATH.back + '/supplierPostage/details', // 商家运费模板详情
    list: MICRO_SERVICE_PATH.back + '/supplierPostage/list', // 商家运费邮寄快递模板列表
    getSameCityContent: MICRO_SERVICE_PATH.back + '/supplierPostage/getSameCityContent', // 商家县域配送运费模板数据
    saveSupplierPostage: MICRO_SERVICE_PATH.back + '/supplierPostage/saveSupplierPostage', // 保存商家运费模板
    saveAddress: MICRO_SERVICE_PATH.back + '/supplierPostage/saveAddress', // 县域配送和自提板块公用的保存地址
    editSameCityDeliveryTime: MICRO_SERVICE_PATH.back + '/supplierPostage/editSameCityDeliveryTime', // 县域配送-保存时间规则
    editSameCityDeliveryTemplate: MICRO_SERVICE_PATH.back + '/supplierPostage/editSameCityDeliveryTemplate', // 县域配送-保存配送模板规则
    getSelfMentionContent: MICRO_SERVICE_PATH.back + '/supplierPostage/getSelfMentionContent', // 获取自提板块内容
    editSelfMethodPostage: MICRO_SERVICE_PATH.back + '/supplierPostage/editSelfMethodPostage', // 设置自提时间
    getSupplierDelivery: MICRO_SERVICE_PATH.back + '/supplierPostage/getSupplierDelivery', // 获取商家支持的配送方式
    closePostage: MICRO_SERVICE_PATH.back + '/supplierPostage/closePostage', // 关闭快递邮寄
    closeSameCityPostage: MICRO_SERVICE_PATH.back + '/supplierPostage/closeSameCityPostage', // 关闭县域配送
    closeSelfMethodPostage: MICRO_SERVICE_PATH.back + '/supplierPostage/closeSelfMethodPostage', // 关闭到店自提
    openPostage: MICRO_SERVICE_PATH.back + '/supplierPostage/openPostage', // 开启快递邮寄
    openSameCityPostage: MICRO_SERVICE_PATH.back + '/supplierPostage/openSameCityPostage', // 开启县域配送
    openSelfMethodPostage: MICRO_SERVICE_PATH.back + '/supplierPostage/openSelfMethodPostage', // 开启到店自提
    setDefaultPostage: MICRO_SERVICE_PATH.back + '/supplierPostage/setDefaultPostage' // 设置为默认运费模板
  },

  /* admin-管理端用户RBAC管理: */
  adminSystemUser: {
    getBankNumberList: MICRO_SERVICE_PATH.back + '/bankInfo/getBankNumberList', // 获取银行列表
    getBankBranchNumberList: MICRO_SERVICE_PATH.back + '/bankInfo/getBankBranchNumberList', // 获取银行列表
  },
  // 商家右上角收通知相关接口
  supplierInform: {
    getMessageServiceNoticeList: MICRO_SERVICE_PATH.other + '/supplierMessage/message/getMessageServiceNoticeList', // 获取未读消息列表
    getUnrealNum: MICRO_SERVICE_PATH.other + '/supplierMessage/message/getUnrealNum', // 获取未读消息数量
    setMsgHaveRead: MICRO_SERVICE_PATH.other + '/supplierMessage/message/setMsgHaveRead', // 设置已读
    getMessageSystemDetails: MICRO_SERVICE_PATH.other + '/supplierMessage/messageSystem/details', // 获取平台消息详情
  },

  // 商家信息管理
  supplier: {
    simpleInfo: MICRO_SERVICE_PATH.back + '/suppSupplier/simpleInfo', // 获取商家账号简单信息
    storeInfo: MICRO_SERVICE_PATH.kernel + '/suppSupplier/storeInfo', // 店铺信息
    editStoreInfo: MICRO_SERVICE_PATH.back + '/suppSupplier/saveStore', // 更新店铺信息
    unBindWx: MICRO_SERVICE_PATH.back + '/suppSupplierWx/unBindWx', // 微信解绑
    changePassword: MICRO_SERVICE_PATH.back + '/suppSupplier/changePassword', // 更改登录密码
    getVillageStores: MICRO_SERVICE_PATH.back + '/suppSupplier/getVillageStores', // 获取镇下面的村

    getSupplierQualification: MICRO_SERVICE_PATH.back + '/suppSupplierUpdate/qualificationReview/getSupplierQualification', // 获取商家经营资质基本信息
    saveSupplierQualificationReview: MICRO_SERVICE_PATH.back + '/suppSupplierUpdate/qualificationReview/saveSupplierQualificationReview', // 提交商家经营资质基本信息审核
    refundAddress: MICRO_SERVICE_PATH.back + '/suppAddress/list', // 获取店铺退货地址
    editStoreRefundAddress: MICRO_SERVICE_PATH.back + '/suppAddress/save', // 编辑退货地址
    setDefaultAddress: MICRO_SERVICE_PATH.back + '/suppAddress/setDefaultAddress', // 设置默认还是非默认地址
    delete: MICRO_SERVICE_PATH.back + '/suppAddress/delete',

    cateList: MICRO_SERVICE_PATH.back + '/supplierCategory/cateList', // 店铺端自定义分类列表
    saveCate: MICRO_SERVICE_PATH.back + '/supplierCategory/saveCate', // 保存店铺自定义分类
    enableOrDisableCate: MICRO_SERVICE_PATH.back + '/supplierCategory/enableOrDisableCate', // 启用或禁用店铺某个自定义分类
    chooseGoods: MICRO_SERVICE_PATH.back + '/supplierCategory/goods/chooseGoods', // 展示店铺可配置商品
    saveBatch: MICRO_SERVICE_PATH.back + '/supplierCategory/goods/saveBatch', // 店铺分类批量商品
    goodsList: MICRO_SERVICE_PATH.back + '/supplierCategory/goods/list', // 店铺分类下的分类商品
    goodsDelete: MICRO_SERVICE_PATH.back + '/supplierCategory/goods/delete', // 店铺分类下的分类商品移除
    deleteCate: MICRO_SERVICE_PATH.back + '/supplierCategory/deleteCate', // 店铺分类移除
  },
  // 商家信息管理
  supplierOrder: {
    list: MICRO_SERVICE_PATH.back + '/suppOrder/list', // 订单列表-new
    info: MICRO_SERVICE_PATH.back + '/suppOrder/info', // 订单详情-new
    waitSendOrderList: MICRO_SERVICE_PATH.back + '/suppOrder/waitSendOrder', // 待发货订单列表-new
    expressCompany: MICRO_SERVICE_PATH.open + '/base/getExpressCompany', // 获取物流公司
    submitBatchSendOrder: MICRO_SERVICE_PATH.back + '/suppOrder/batchOrderSend', // 合并单号发货-new
    submitSingleSend: MICRO_SERVICE_PATH.back + '/suppOrder/singleSend', // 单笔订单发货，可进行拆单发货-new
    refundOrderList: MICRO_SERVICE_PATH.back + '/suppOrder/getRefundOrderList', // 售后订单列表-new
    refundOrderDetail: MICRO_SERVICE_PATH.back + '/suppOrder/orderRefundDetail', // 售后订单详情
    reviewRefundSuccess: MICRO_SERVICE_PATH.back + '/suppOrder/reviewRefundSuccess', // 同意进行售后申请
    reviewRefundRefuse: MICRO_SERVICE_PATH.back + '/suppOrder/reviewRefundRefuse', // 拒绝售后申请
    refundConfirmRefund: MICRO_SERVICE_PATH.back + '/suppOrder/refundConfirmRefund', // 售后确认收货
    operatingNotReceivedOrder: MICRO_SERVICE_PATH.back + '/suppOrder/operatingNotReceivedOrder', // 商家未收到退货关闭售后单操作
    refundOrderReSend: MICRO_SERVICE_PATH.back + '/suppOrder/refundOrderReSend', // 换货，商家收到货后重新发货
    getOrderExpressList: MICRO_SERVICE_PATH.back + '/suppOrder/getOrderExpressList', // 获取订单已发货的信息
    getSameAddressOrder: MICRO_SERVICE_PATH.back + '/suppOrder/getSameReceiveInfoList', // 获取同一个供应商相同收货地址的订单
    express: MICRO_SERVICE_PATH.back + '/suppOrder/express', // 查看物流信息
  },
  // 商家 品牌管理模块
  supplierBrand: {
    addSupplierBrand: MICRO_SERVICE_PATH.back + '/suppBrand/addSupplierBrand', // 商家申请品牌入驻
    supplierBrandUsing: MICRO_SERVICE_PATH.back + '/suppBrand/supplierBrandUsing', // 品牌入驻使用中的详情,审核中状态的不应该调用
    supplierBrandInfo: MICRO_SERVICE_PATH.back + '/suppBrand/supplierBrandInfo', // 申请入驻品牌详情
    supplierBrandList: MICRO_SERVICE_PATH.back + '/suppBrand/supplierBrandList', // 商家品牌入驻列表
    searchCommonBrand: MICRO_SERVICE_PATH.back + '/suppBrand/searchCommonBrand', // 搜素公共品牌
    searchCommonCategory: MICRO_SERVICE_PATH.back + '/suppBrand/searchCommonCategory', // 获取公共分类列表
  },
  // 商家 商品模块
  supplierGoods: {
    deleteDraftOrReviewGoods: MICRO_SERVICE_PATH.back + '/suppGoods/goods/deleteDraftOrReviewGoods', // 删除草稿箱
    cancelReviewGoods: MICRO_SERVICE_PATH.back + '/suppGoods/goods/cancelReviewGoods', // 撤销商品审核
    deleteGoods: MICRO_SERVICE_PATH.back + '/suppGoods/goods/deleteGoods', // 删除商品
    draftData: MICRO_SERVICE_PATH.back + '/suppGoods/goods/draftData', // 获取草稿详情
    draftList: MICRO_SERVICE_PATH.back + '/suppGoods/goods/draftList', // 获取草稿箱列表
    reviewList: MICRO_SERVICE_PATH.back + '/suppGoods/goods/reviewList', // 获取商家提交的商品审核列表
    editStock: MICRO_SERVICE_PATH.back + '/suppGoods/goods/editStock', // 修改库存
    getCateAttrItemVals: MICRO_SERVICE_PATH.back + '/suppGoods/goods/getCateAttrItemVals', // 通过分类获取属性项及值
    getFreeTemplateList: MICRO_SERVICE_PATH.back + '/suppGoods/goods/getFreeTemplateList', // 获取商家配置的运费模板
    getSupplierBrandByCate: MICRO_SERVICE_PATH.back + '/suppGoods/goods/getSupplierBrandByCate', // 新增商品通过三级分类查找品牌
    getSupplierBrandCateList: MICRO_SERVICE_PATH.back + '/suppGoods/goods/getSupplierBrandCateList', // 新增/编辑商品时获取商家入驻分类列表
    goodsInfo: MICRO_SERVICE_PATH.back + '/suppGoods/goods/goodsInfo', // 获取商品详情
    list: MICRO_SERVICE_PATH.back + '/suppGoods/goods/list', // 商家平台上下架商品列表
    deletedList: MICRO_SERVICE_PATH.back + '/suppGoods/goods/deletedList', // 商家平台已删除列表
    offShelf: MICRO_SERVICE_PATH.back + '/suppGoods/goods/offShelf', // 商品下架
    onShelf: MICRO_SERVICE_PATH.back + '/suppGoods/goods/onShelf', // 商品上架
    doTop: MICRO_SERVICE_PATH.back + '/suppGoods/goods/doTop', // 设置/取消商品置顶接口
    toggleRecommend: MICRO_SERVICE_PATH.back + '/suppGoods/goods/toggleRecommend', // 设置/取消商品精选推荐
    isSupportGift: MICRO_SERVICE_PATH.back + '/suppGoods/goods/isSupportGift', // 设置/ 是否赠送
    saveGoodsDraft: MICRO_SERVICE_PATH.back + '/suppGoods/goods/saveGoodsDraft', // 保存草稿
    saveReviewGoods: MICRO_SERVICE_PATH.back + '/suppGoods/goods/saveReviewGoods', // 新增商品提交审核
    stockList: MICRO_SERVICE_PATH.back + '/suppGoods/goods/stockList', // 按sku规格展示商品列表库存信息
    getWxaCode: MICRO_SERVICE_PATH.back + '/suppGoods/goods/getWxaCode', // 获取商品推广码
    getGoodsEditConfig: MICRO_SERVICE_PATH.back + '/suppGoods/goods/getGoodsEditConfig', // 获取商家编辑商品时候的配置
    preSellList: MICRO_SERVICE_PATH.back + '/suppGoods/goods/preSellList', //预告商品列表
  },

  suppSupplierStore: { // 商家店铺管理
    getStoreWxaCode: MICRO_SERVICE_PATH.back + '/suppSupplierStore/getStoreWxaCode', // 获取店铺推广码链接
    configAdvert: MICRO_SERVICE_PATH.back + '/suppSupplierStore/configAdvert', // 店铺广告轮播批量保存
    advertList: MICRO_SERVICE_PATH.back + '/suppSupplierStore/advertList', // 店铺广告轮播列表
    advertJumpType: MICRO_SERVICE_PATH.back + '/suppSupplierStore/advertJumpType', // 店铺广告轮播跳转类型
    advertEnableOrDisable: MICRO_SERVICE_PATH.back + '/suppSupplierStore/advertEnableOrDisable', // 店铺广告轮播启用/禁用
    advertTagDelete: MICRO_SERVICE_PATH.back + '/suppSupplierStore/advertTagDelete', // 店铺广告轮播删除
    bannerList: MICRO_SERVICE_PATH.back + '/suppSupplierStore/list', // 云集市店铺广告轮播列表
    deleteNavigate: MICRO_SERVICE_PATH.back + '/suppSupplierStore/deleteNavigate', // 云集市店铺广告轮播列表
    batchSaveNavigate: MICRO_SERVICE_PATH.back + '/suppSupplierStore/batchSaveNavigate', // 云集市店铺广告轮播列表
  },

  /* supp-商家-商品评价审核模块 */
  suppEvaluationAudit: {
    approvedList: MICRO_SERVICE_PATH.back + '/suppEvaluationAudit/approvedList', // 审核通过的商品评论
    isRecommend: MICRO_SERVICE_PATH.back + '/suppEvaluationAudit/isRecommend', // 将某个商品评论设置到商品详情页推荐位
  },
  /* 商家 商家基本信息管理模块 */
  supplierBasicMessage: {
    getIndexData: MICRO_SERVICE_PATH.back + '/suppSupplier/getIndexData', // 获取商家首页数据
    getRegisterPhoneCode: MICRO_SERVICE_PATH.back + '/suppSupplier/getRegisterPhoneCode', // 获取商家入驻手机验证码
    getSupplierCheckData: MICRO_SERVICE_PATH.back + '/suppSupplier/getSupplierCheckData', // 获取商家审核数据 （获取已保存的草稿、已提交的审核）
    register: MICRO_SERVICE_PATH.back + '/suppSupplier/register', // 商家注册
    saveSupplierCheckData: MICRO_SERVICE_PATH.back + '/suppSupplier/saveSupplierCheckData', // 保存店铺资料审核数据（保存草稿）
    submitSupplierCheckData: MICRO_SERVICE_PATH.back + '/suppSupplier/submitSupplierCheckData', // 提交店铺资料审核数据
    forgetPassword: MICRO_SERVICE_PATH.back + '/suppSupplier/forgetPassword', // 忘记密码
    getForgetPhoneCode: MICRO_SERVICE_PATH.back + '/suppSupplier/getForgetPhoneCode', // 获取忘记密码手机验证码
  },
  /* supp-商家平台-资产管理 */
  suppWallet: {
    getAssetCenterInfo: MICRO_SERVICE_PATH.back + '/suppWallet/getAssetCenterInfo', // 资产中心页面接口
    getSupplierWalletDetails: MICRO_SERVICE_PATH.back + '/suppSupplierWithdrawls/getSupplierWalletDetails', // 商家可提现基本信息
    getSupplierBills: MICRO_SERVICE_PATH.back + '/suppWallet/getSupplierBills', // 收支明细
    getSupplierBillSum: MICRO_SERVICE_PATH.back + '/suppWallet/getSupplierBillSum', // 收支明细统计
    getSupplierSettleList: MICRO_SERVICE_PATH.back + '/suppWallet/getSupplierSettleList', // 订单流水列表
    getSupplierSettleDetail: MICRO_SERVICE_PATH.back + '/suppWallet/getSupplierSettleDetail', // 订单流水列表
    getSupplierSettleSum: MICRO_SERVICE_PATH.back + '/suppWallet/getSupplierSettleSum', // 订单流水统计数据
    getSupplierRevenueFlowList: MICRO_SERVICE_PATH.back + '/suppWallet/getSupplierRevenueFlowList', // 收入流水
    sendSupplierWithdrawlsCode: MICRO_SERVICE_PATH.back + '/suppSupplierWithdrawls/sendSupplierWithdrawlsCode', // 商家提现手机验证码
    withdrawlsSupplierWallet: MICRO_SERVICE_PATH.back + '/suppSupplierWithdrawls/withdrawlsSupplierWallet', // 商家提现
    getSupplierWithdrawlsList: MICRO_SERVICE_PATH.back + '/suppSupplierWithdrawls/getSupplierWithdrawlsList', // 商家申请提现列表
    getSupplierSettlement: MICRO_SERVICE_PATH.back + '/suppSupplierUpdate/settlementReview/getSupplierSettlement', // 商家银行卡信息
    saveSupplierSettlementReview: MICRO_SERVICE_PATH.back + '/suppSupplierUpdate/settlementReview/saveSupplierSettlementReview', // 修改商家银行卡信息
  },

  /* supp-商家后台佣金服务费管理模块 */
  suppCommission: {
    getSupplierCommissionList: MICRO_SERVICE_PATH.back + '/suppCommission/getSupplierCommissionList', // 获取店铺佣金服务费明细
    getCommissionsDetail: MICRO_SERVICE_PATH.back + '/suppCommission/getCommissionsDetail', // 佣金明细详情
    getSupplierCommissionsSum: MICRO_SERVICE_PATH.back + '/suppCommission/getSupplierCommissionsSum', // 佣金明细统计
  },
  suppStatistic: {
    fanStatistic: MICRO_SERVICE_PATH.back + '/suppFansData/basisData', // 粉丝统计数据
    kernelStatistic: MICRO_SERVICE_PATH.back + '/suppTradeData/kernelData', // 交易统计核心数据
    tradeStatistic: MICRO_SERVICE_PATH.back + '/suppTradeData/basisData', // 交易统计数据
    goodsStatisticList: MICRO_SERVICE_PATH.back + '/suppGoodsData/goodsData', // 商品分析
  },

  /* supp-商家后台佣金服务费管理模块 */
  supplierSpikeGoods: {
    delete: MICRO_SERVICE_PATH.back + '/suppSpikeGoods/delete', // 删除限时秒杀活动
    details: MICRO_SERVICE_PATH.back + '/suppSpikeGoods/details', // 获取限时秒杀详情
    list: MICRO_SERVICE_PATH.back + '/suppSpikeGoods/list', // 获取限时秒杀列表
    saveOrUpdate: MICRO_SERVICE_PATH.back + '/suppSpikeGoods/saveOrUpdate', // 新增或修改限时秒杀
    spikeGoodsAddList: MICRO_SERVICE_PATH.back + '/suppSpikeGoods/spikeGoodsAddList', // 商品列表
    spikeGoodsAddListGroupGoodsId: MICRO_SERVICE_PATH.back + '/suppSpikeGoods/spikeGoodsAddListGroupGoodsId', // 商品新增列表，根据商品分组sku
    updateInvalidation: MICRO_SERVICE_PATH.back + '/suppSpikeGoods/updateInvalidation', // 修改为失效
    listThemeConfigs: MICRO_SERVICE_PATH.back + '/suppSpikeGoods/listThemeConfigs'
  },
  /* supp-商家后台营销活动推广带货 */
  supplierPromotingGoods: {
    listOfPromotionGoods: MICRO_SERVICE_PATH.back + '/adminPromotionGoods/listOfPromotionGoods', // 查询推广商品列表
    addPromotionAdmin: MICRO_SERVICE_PATH.back + '/adminPromotionGoods/addPromotionAdmin', // 新增商品
    updatePromotionAdmin: MICRO_SERVICE_PATH.back + '/adminPromotionGoods/updatePromotionAdmin', // 编辑商品
    promotionGoodsAddListGroupGoodsId: MICRO_SERVICE_PATH.back + '/adminPromotionGoods/promotionGoodsAddListGroupGoodsId', // 新增商品，商品分组
    deletePromotionGoods: MICRO_SERVICE_PATH.back + '/adminPromotionGoods/deletePromotionGoods', // 批量删除或终止推广商品
    detailsOfPromotionFee: MICRO_SERVICE_PATH.back + '/adminPromotionGoods/detailsOfPromotionFee', // 推广费明细
    promotionGoodsEcho: MICRO_SERVICE_PATH.back + '/adminPromotionGoods/promotionGoodsEcho', // 修改推广商品回显
  },

  // supp-商家后台会员管理
  memberApi: {
    getFollowStoreUsers: MICRO_SERVICE_PATH.back + '/suppUser/getFollowStoreUsers', // 关注会员列表
    editFollowStoreUserRemark: MICRO_SERVICE_PATH.back + '/suppUser/editFollowStoreUserRemark', // 修改关注会员的备注信息
    getBindStoreUser: MICRO_SERVICE_PATH.back + '/suppUser/getBindStoreUser', // 获取绑定会员列表
  },
  // 关联产业监控
  monitorApi: {
    getMonitorAidsList: MICRO_SERVICE_PATH.back + '/suppGoods/getMonitorAidsList', // 获取监控列表
    saveGoodsSpecial: MICRO_SERVICE_PATH.back + '/suppGoodsSpecial/goodsSpecial', // 保存特殊商品分类
    getSpecialDetailed: MICRO_SERVICE_PATH.back + '/suppGoodsSpecial/goodsSpecialDetailed', // 获取特殊商品分类明细
    deleteSpecial: MICRO_SERVICE_PATH.back + '/suppGoodsSpecial/goodsSpecialDetailed', // 删除特殊商品分类明细
    saveSpecial: MICRO_SERVICE_PATH.back + '/suppGoodsSpecial/goodsSpecialDetailed', // 保存特殊商品分类明细
    updateGoodsSpecial: MICRO_SERVICE_PATH.back + '/suppGoodsSpecial/updateGoodsSpecialDetailedBatchCancel/', // 批量下架特殊商品分类明细
  },

  farm: {
    farmLandPage: `${MICRO_SERVICE_PATH.farm}/farm/land/page`, // 土地认租认种
    farmLandSave: `${MICRO_SERVICE_PATH.farm}/farm/land/save`,
    farmLandUpdate: `${MICRO_SERVICE_PATH.farm}/farm/land/update`,
    farmLandDelete: `${MICRO_SERVICE_PATH.farm}/farm/land/delete`,
    farmLandGetById: `${MICRO_SERVICE_PATH.farm}/farm/land/getById/`,
    farmLandUpdateStatus: `${MICRO_SERVICE_PATH.farm}/farm/land/updateStatus`,

    farmLanddistrictList: `${MICRO_SERVICE_PATH.farm}/farm/landdistrict/list`, // 土地分区配置
    farmLanddistrictLabelList: `${MICRO_SERVICE_PATH.farm}/farm/landdistrict/labelList`,
    farmLanddistrictDelete: `${MICRO_SERVICE_PATH.farm}/farm/landdistrict/delete/`,
    farmLanddistrictSave: `${MICRO_SERVICE_PATH.farm}/farm/landdistrict/save`,

    // 农场种子列表
    farmSeedPage: `${MICRO_SERVICE_PATH.farm}/farm/seed/page`, // 种子列表
    farmSeedDelete: `${MICRO_SERVICE_PATH.farm}/farm/seed/delete/`, // 删除
    farmSeedGetById: `${MICRO_SERVICE_PATH.farm}/farm/seed/getById/`, // 信息
    farmSeedSave: `${MICRO_SERVICE_PATH.farm}/farm/seed/save`, // 新增种子
    farmSeedUpdate: `${MICRO_SERVICE_PATH.farm}/farm/seed/update`, // 编辑
    farmSeedUpdateStatus: `${MICRO_SERVICE_PATH.farm}/farm/seed/updateStatus`, // 种子上架、下架

    //  农场租赁套餐管理
    farmRentPackagePage: `${MICRO_SERVICE_PATH.farm}/farm/rentPackage/page`, // 种子列表
    farmRentPackageSave: `${MICRO_SERVICE_PATH.farm}/farm/rentPackage/save`,
    farmRentPackageUpdate: `${MICRO_SERVICE_PATH.farm}/farm/rentPackage/update`,
    farmRentPackageLabel: `${MICRO_SERVICE_PATH.farm}/farm/rentPackage/label`, // 租凭套餐下拉列表
    farmRentPackageGetMaxSort: `${MICRO_SERVICE_PATH.farm}/farm/rentPackage/getMaxSort`, // 获取最大序号
    farmRentPackageGetById: `${MICRO_SERVICE_PATH.farm}/farm/rentPackage/getById/`, // 信息
    farmRentPackageEnableOnDisable: `${MICRO_SERVICE_PATH.farm}/farm/rentPackage/enableOnDisable`, // 启用或禁用
    farmRentPackageDelete: `${MICRO_SERVICE_PATH.farm}/farm/rentPackage/delete/`,

    // 租赁套餐关联服务
    farmRentpackagerelateserviceListByPackageId: `${MICRO_SERVICE_PATH.farm}/farm/rentpackagerelateservice/listByPackageId/`, // 通过套餐查询服务
    farmRentpackagerelateserviceSaveOrUpdate: `${MICRO_SERVICE_PATH.farm}/farm/rentpackagerelateservice/saveOrUpdate`,

    // 农场管理员
    farmUserPage: `${MICRO_SERVICE_PATH.farm}/farm/user/page`,
    farmUserSave: `${MICRO_SERVICE_PATH.farm}/farm/user/save`,
    farmUserUpdate: `${MICRO_SERVICE_PATH.farm}/farm/user/update`,
    farmUserDelete: `${MICRO_SERVICE_PATH.farm}/farm/user/delete`,
    farmSserGetCzlUserList: `${MICRO_SERVICE_PATH.farm}/farm/user/getCzlUserList`,

    // 土地授权列表
    farmLandrentPage: `${MICRO_SERVICE_PATH.farm}/farm/landrent/page`,
    farmLandrentChangeLandPlantStatus: `${MICRO_SERVICE_PATH.farm}/farm/landrent/changeLandPlantStatus`,
    farmLandrentGetById: `${MICRO_SERVICE_PATH.farm}/farm/landrent/getById/`,
    farmlandrentgetOnlineStoreBalanceList: `${MICRO_SERVICE_PATH.farm}/farm/landrent/getOnlineStoreBalanceList/`,

    // 土地租用订单
    farmLandrentorderPage: `${MICRO_SERVICE_PATH.farm}/farm/landrentorder/page`,

    // 任务大厅
    farmTaskPage: `${MICRO_SERVICE_PATH.farm}/farm/task/page`,
    farmTaskGetById: `${MICRO_SERVICE_PATH.farm}/farm/task/getById/`,
    farmTtaskConfirmTask: `${MICRO_SERVICE_PATH.farm}/farm/task/confirmTask/`,

    // 服务类型配置
    farmServiceTypePriceList: `${MICRO_SERVICE_PATH.farm}/farm/serviceTypePrice/list`,
    farmServiceTypePriceUpdate: `${MICRO_SERVICE_PATH.farm}/farm/serviceTypePrice/update`,
    farmServiceTypePriceUpdateEnableStatus: `${MICRO_SERVICE_PATH.farm}/farm/serviceTypePrice/updateEnableStatus`,

    // 仓库明细列表
    farmStoreInoutDetailPage: `${MICRO_SERVICE_PATH.farm}/farm/storeInoutDetail/page`,

    // 服务结余数量列表
    farmServicetypeblanceList: `${MICRO_SERVICE_PATH.farm}/farm/servicetypeblance/list`,

    // 农场记录列表
    farmrecordlist: `${MICRO_SERVICE_PATH.farm}/farm/record/list`,
    farmrecordsave: `${MICRO_SERVICE_PATH.farm}/farm/record/save`,
    farmrecordupdate: `${MICRO_SERVICE_PATH.farm}/farm/record/update`,
    farmrecordgetLabelByLandDistrictId: `${MICRO_SERVICE_PATH.farm}/farm/record/getLabelByLandDistrictId`,
    farmrecordgetById: `${MICRO_SERVICE_PATH.farm}/farm/record/getById/`,
  }

}
export const URL_MINOR = {}
export const URL_AUTH = { // 统一登录
  loginSupplier: MICRO_SERVICE_PATH.back + '/suppSupplier/token', // 商家用户登录
  getSupplierToken: MICRO_SERVICE_PATH.back + '/suppSupplier/getSupplierToken', // 通过县镇村端免密登录
}
