export let templateElDrawer = {
  props:{
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    customClass: { // Drawer 的自定义类名
      type: String,
      default: ''
    },
    appendToBody: { // Drawer 自身是否插入至 body 元素上。嵌套的 Drawer 必须指定该属性并赋值为 true
      type: Boolean,
      default: true
    },
    beforeClose: { //关闭前的回调，会暂停 Drawer 的关闭
      type: Function,
    },
    closeOnPressEscape: { // 是否可以通过按下 ESC 关闭 Drawer
      type: Boolean,
      default: true
    },
    wrapperClosable: { // 点击遮罩层是否可以关闭 Drawer
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: '60%',
    },
    closed: {
      type: Function,
      default:function(){},
    },
    modal: { // 是否需要遮罩层
      type: Boolean,
      default: true
    },
  },
  data(){
    return {
      visibleValue:false,
    }
  },
  created () {
    this.visibleValue = !!this.visible;
  },
  methods:{
    closedCallback(){
      if(typeof this.closed === 'function') this.closed();
      this.$emit('closed');
    }
  },
  watch:{
    visible(newValue){
      console.log('watch visible', newValue)
      this.visibleValue = newValue
    },
    visibleValue(newValue) {
      console.log('watch visibleValue', newValue)
      this.$emit('update:visible', newValue)
    },
  }
}
