<template>
  <div class="download-excel-wrap">
    <div class="download-excel-fixed-box "
         :class="{active:visibleValue}"
         v-loading="loading">
      <div class="download-excel-container">
        <div class="download-excel-header flex-item">
          <div class="content title">{{title}}</div>
          <div class="right close" @click="hide"><el-icon class="el-icon-close"/></div>
        </div>
        <div class="download-excel-main" id="downloadExcelMain" v-infinite-scroll="loadMore">
          <template v-if="list.length">
            <download-excel-item :data="item"
                                 v-for="(item,index) in list" :key="item.id"></download-excel-item>
          </template>
          <div class="no-data" v-else>
            <el-icon class="no-data-icon el-icon-document-delete" />
            <div class="no-data-text">暂无记录</div>
          </div>
        </div>
        <!--<div class="download-excel-footer"></div>-->
      </div>
    </div>
    <div class="download-excel-fixed-action" v-if="!visibleValue">
      <el-button type="" @click="show" icon="el-icon-document">excel</el-button>
    </div>
  </div>
</template>

<script>
  import {templateElDrawer} from '../template/template-el-drawer';
  import {mapState,mapGetters} from 'vuex';
  import {isEmpty,parseTimeArray,deepsClone} from '../../../assets/js/utils';
  import {URL_MINOR} from '../../../config'
  import downloadExcelItem from './downloadExcelItem'

  export default {
    name: 'downloadExcel',
    mixins:[templateElDrawer],
    components:{
      downloadExcelItem
    },
    props:{
      title:{
        type:String,
        default:'Excel任务列表',
      },
      size: {
        type: String,
        default: '300px',
      },
      modal: { // 是否需要遮罩层
        type: Boolean,
        default: true,
      },
    },
    data(){
      return {
        loading:false,
        /* 列表 */
        currentPage: 1, // 当前页码
        totalListNum: 10, // 列表总条量
        pageSize: 10, // 每页显示得条数
        list:[
          // {
          //   name:'测试表格下载列表',
          //   url:'https://pp.com/item/project/board?pid=f572f070-cb66-43fb-aa72-d691c4035ede',
          //   // params:{},// 创建任务的需要的参数,
          // }
        ],
      }
    },
    created(){

    },
    methods:{
      show(){
        this.visibleValue = true;
      },
      hide(){
        this.visibleValue = false;
      },
      /**
       *
       */
      getList(){
        if(this.list.length >= this.totalListNum) return console.log('不再加载');
        if(this.loading) return console.log('正在加载');
        let data = {
          pageNo: this.currentPage,
          pageSize: this.pageSize,
          userType:3,
        };
        this.loading = true;
        this.axios.get(URL_MINOR.commonFile.getSystemExcelList, {params: data}).then(res => {
          // parseTimeArray(res.data.records, ["lastAccessTime"]);
          if(this.currentPage === 1){
            this.totalListNum = res.data.total;
          }
          this.currentPage += 1;
          this.list.push(...res.data.records);
        }).catch(res=>{

        }).finally(res=>{
          this.loading = false;
        })
      },
      /**
       * 判断列表中是否已有对应createId的任务
       * */
      hascreatedId(id = ''){
        return this.list.filter(res=>res.createdId).some(res=> res.createdId === id);
      },
      /**
       * 加载更多
       * */
      loadMore(){
        this.getList();
      },
    },
    computed:{
      ...mapGetters({
        newTask:'excel/newTask',
      }),
    },
    watch:{
      newTask(newValue){
        if(isEmpty(newValue)) return false;
        this.totalListNum += 1;
        let map = deepsClone(newValue);
        map.id = '临时ID_'+map.name + new Date().getTime() + parseInt(Math.random() * 1e5);
        this.visibleValue = true;
        document.getElementById('downloadExcelMain').scrollTop = 0
        this.list.unshift(map);
      },
    }
  }
</script>

<style scoped lang="less">
  @import (reference) "../../../assets/css/data";
  .download-excel-fixed-box{
    font-size: 14px;
  }
  .download-excel-action{
    margin: @margin-primary auto;
  }

  .download-excel-wrap{
    position: fixed;
    z-index: 9;
    right: 0;
    bottom: 0;
  }
  .download-excel-fixed-action{
    position: fixed;
    z-index: 8;
    right: 40px;
    bottom: 40px;
    display: none;
    & .el-button{
      width: 112px;
    }
  }
  @excel_width:360px;
  .download-excel-fixed-box{
    position: fixed;
    z-index: 9;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0;
    background-color: white;
    transition: width 0.4s ease,opacity 0.4s ease;
    box-shadow: -4px 0 10px #eee;
    opacity: 0.6;
    &.active{
      width: @excel_width;
      opacity: 1;
    }
  }
  .download-excel-container{
    width: @excel_width;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .download-excel-header,
  .download-excel-main,
  .download-excel-footer{
    margin-bottom: 20px;
    padding: 0 20px
  }
  .download-excel-header{
    border-bottom: 1px solid #eee;
    .title{
      color: @font-color-heading-text;
      font-size: @font-size-heading;
    }
    .close{
      padding: 5px 10px;
      margin-right: -10px;
      cursor: pointer;
      font-size: @font-size-heading;
      &:hover{
        color: @color-danger;
      }
    }
  }
  .download-excel-main{
    flex: 1;
    overflow-y: auto;
  }
  .download-excel-footer{

  }
  .no-data{
    color: @color-info;
    margin: 60px auto;
    text-align: center;
    .no-data-icon{
      font-size: 60px;
    }
    .no-data-text{
      margin: @margin-primary;
    }
  }
</style>
