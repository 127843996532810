<template>
    <el-container class="home-wrap style-primary">
        <el-aside :class="['home-aside',collapse ? 'menu-close':'menu-open']">
            <div class="system-info">
                <el-avatar class="system-logo" circle="square" fit="cover"
                           :src="logoUrl" v-if="collapse"></el-avatar>
                <h1 class="system-name" v-else>{{ $store.state.systemName }}</h1>
            </div>
            <el-menu :class="['el-menu-vertical-demo','home-menu',collapse ? 'close':'open']"
                     :default-active="defaultActive"
                     :default-openeds="defaultOpeneds"
                     :router="true"
                     :unique-opened="false"
                     :collapse="collapse"
                     :collapse-transition="false">

                <template v-for="item in menuList">

                    <el-submenu :key="item.id"
                                :index="item.id"
                                :disabled="item.disabled"
                                v-if="typeof item.children === 'object' && item.children.length && item.show">
                        <template slot="title">
                            <i :class="item.icon?item.icon:'el-icon-menu'"></i>
                            <span slot="title">{{ item.name }}</span>
                        </template>

                        <template v-for="item2 in item.children">
                            <el-submenu :key="item2.id"
                                        :index="item2.id"
                                        :disabled="item2.disabled"
                                        v-if="typeof item2.children === 'object' && item2.children.length">
                                <template slot="title">
                                    <i :class="item2.icon?item2.icon:'el-icon-collection'"></i>
                                    <span slot="title">{{ item2.name }}</span>
                                </template>
                                <template v-for="item3 in item2.children">
                                    <el-menu-item :key="item3.id"
                                                  :index="item3.id"
                                                  :route="item3.url"
                                                  v-if="item3.show"
                                                  :disabled="item3.disabled">
                                        <i :class="item3.icon?item3.icon:'el-icon-collection-tag'"></i>
                                        <span slot="title">{{ item3.name }}</span>
                                    </el-menu-item>
                                </template>
                            </el-submenu>
                            <el-menu-item :key="item2.id"
                                          :index="item2.id"
                                          :route="item2.url"
                                          :disabled="item2.disabled"
                                          v-else-if="item2.show">
                                <!-- <i :class="item2.icon?item2.icon:'el-icon-collection-tag'"></i> -->
                                <span slot="title">{{ item2.name }}</span>
                            </el-menu-item>
                        </template>

                    </el-submenu>

                    <el-menu-item :key="item.id"
                                  :index="item.id"
                                  :route="item.url"
                                  :disabled="item.disabled"
                                  v-else-if="item.show">
                        <i :class="item.icon?item.icon:'el-icon-s-home'"></i>
                        <span slot="title">{{ item.name }}</span>
                    </el-menu-item>

                </template>

            </el-menu>

        </el-aside>
        <el-container class="over-hidden home-right">
            <el-header class="home-header">
                <div class="cursor-pointer" @click="triggerCollapse">
                    <el-icon :class="collapse? 'el-icon-s-unfold':'el-icon-s-fold'"></el-icon>
                </div>
                <div class="flex-1"></div>
                <!-- 消息通知 -->
                <!-- <el-popover
                  placement="bottom"
                  width="360"
                  :offset="100"
                  @show="showMessage"
                  trigger="click">
                        <messageNotification :messageNumData="messageNumData" @setMsgHaveRead='setMsgHaveRead'></messageNotification>
                        <div class="cursor-pointer margin-right-10 color-primary show-notification" slot="reference" @click="showNotification">
                          <i class="el-icon-message-solid" style="font-size: larger;"></i>
                          <span class="notification-num" v-if="messageNumData.orderUnreadMsgNum + messageNumData.platformUnreadMsgNum + messageNumData.storeUnreadMsgNum != 0">{{messageNumData.orderUnreadMsgNum + messageNumData.platformUnreadMsgNum + messageNumData.storeUnreadMsgNum}}</span>
                          <span style="font-size: smaller">消息通知</span>
                        </div>
                </el-popover> -->
                <!-- 分享店铺 -->
                <!-- <template v-if="$store.state.userInfo">
                  <div class="cursor-pointer margin-right-10 color-primary" style="display: flex; align-items: center;"
                       @click="visibleDialogQrStore=true">
                    <i class="el-icon-s-shop" style="font-size: larger;"></i>
                    <span style="font-size: smaller">分享店铺</span>
                  </div>
                  <canvas-qr-store :visible.sync="visibleDialogQrStore" :user-info="userInfo"></canvas-qr-store>
                </template> -->
                <div class="home-header-content home-header-right">
                    <el-popover
                        placement="bottom"
                        trigger="hover">
                        <div class="user-popup">
                            <p class="">
                                <el-button type="danger" class="login-out" @click="loginOut">退出登录</el-button>
                            </p>
                        </div>
                        <div class="user-box" slot="reference">
                            <el-avatar class="user-avatar" circle="square" fit="cover"
                                       :src="userInfo.userLogo"></el-avatar>
                            <div class="user-name">{{ userInfo.userName }}</div>
                            <el-icon class="el-icon-caret-bottom"></el-icon>
                        </div>
                    </el-popover>
                </div>
            </el-header>
            <div class="home-router-wrap" v-if="openIframeTabs">
                <home-router-tabs></home-router-tabs>
            </div>
            <el-main class="home-main" id="homeMain">
                <keep-alive :include="includeList">
                    <router-view :certificationStatus="certificationStatus" :key="key"/>
                </keep-alive>
            </el-main>
            <excel :visible.sync="showDownloadExcel" v-if="token"></excel>
        </el-container>
    </el-container>
</template>

<script>
import HOME_MENU from '../mock/homeMenu/menu'
import {isEmpty, stringToBoolean} from '../assets/js/utils'
import {isTest, URL} from '../config'
import homeRouterTabs from '../components/public/homeRouterTabs/homeRouterTabs'
import excel from '../components/public/downloadExcel/downloadExcel'
import store from '../store/index'
import {mapGetters, mapState} from 'vuex';
import canvasQrStore from '../components/supplier/canvasQr/canvasQrStore'
import messageNotification from '../components/supplier/messageNotification/messageNotification'
import {requestUserInfo} from "../assets/js/publicRequest";
// 开启 仿iframe样式Tabs栏
let openIframeTabs = false;
export default {
    name: 'Home',
    components: {
        homeRouterTabs,
        excel,
        canvasQrStore,
        messageNotification
    },
    data() {
        return {
            logoUrl: '/images/logo/logo.png',
            certificationStatus: true,
            openIframeTabs,
            // 收起菜单
            collapse: false,
            menuList: [],
            defaultActive: "0",
            defaultOpeneds: ['0'],
            showDownloadExcel: false,
            /* 扫码进店*/
            visibleDialogQrStore: false,
            messageNumData: {
                orderUnreadMsgNum: 0,
                platformUnreadMsgNum: 0,
                storeUnreadMsgNum: 0
            }
        }
    },
    created() {
        this.collapse = stringToBoolean(localStorage.getItem('home_menu_has_collapse'));
        this.$nextTick(()=>{
            requestUserInfo().then((res) => {
                this.postMenuList(res).then(() => {
                    this.countMenuListDefaults()
                });
            })
        })

    },
    methods: {
        /**
         * 请求菜单列表
         */
        postMenuList(data) {
            this.assignMenuListTestId(HOME_MENU);
            if (data.data.certificationStatus != 1) { //未论证
                this.certificationStatus = false
                for (let i = 1; i < HOME_MENU.length; i++) {
                    HOME_MENU.splice(i, 1)
                    i--
                }
            } else {  //已论证
                this.certificationStatus = true
            }
            this.menuList = HOME_MENU;
            return Promise.resolve({
                code: 0,
                data: HOME_MENU,
            });
        },
        // 打开
        showMessage() {
            if (this.messageNumData.orderUnreadMsgNum != 0) {
                this.setMsgHaveRead(1)
            }
        },
        // 获取未读消息数量
        getMessageunreadCount() {
            //  this.axios.get(URL.supplierInform.getUnrealNum, {params:{userClientType:'31'}}).then(res => {
            //    this.messageNumData  = res.data
            //   }).catch(res=>{
            //   }).finally(res=>{
            //   })
        },
        // 设置已读
        setMsgHaveRead(tag) {
            // this.axios.post(URL.supplierInform.setMsgHaveRead, this.qs.stringify({userClientType:'31', msgType: tag})).then(res => {
            //    this.getMessageunreadCount()
            //   }).catch(res=>{
            //   }).finally(res=>{
            //   })
        },
        assignMenuListTestId(list = [], beforeId = '') {
            return list.forEach((res, index) => {
                if (isEmpty(res.id)) res.id = beforeId + index
                if (typeof res.children === 'object' && res.children.length) this.assignMenuListTestId(res.children, beforeId + `${index}-`)
            })
        },
        /**
         * 根据 this.$route.path && menuList 计算默认值，保证第一次打开页面menu正确展开选中
         */
        countMenuListDefaults(routeData = {}) {
            let $route = isEmpty(routeData) ? this.$route : routeData;
            let path = $route.path;
            let defaultIndex = ['0'];
            let defaultActive = '0';
            let countResult = this.forEachDefaultsSelect(path, this.menuList)
            if (countResult) {
                console.log('%c 匹配最终结果', 'color:green', countResult)
                defaultIndex = countResult.defaultIndex
                defaultActive = countResult.id
                if (isEmpty(routeData)) this.defaultOpeneds = defaultIndex;
                this.defaultActive = defaultActive;
            }
        },
        forEachDefaultsSelect(path = '', list = [], level = 0) {
            let result = false
            let urls = list.map(res => res.url)
            let isActive = urls.includes(path);
            if (isActive) {
                let index = urls.indexOf(path)
                let id = list[index].id
                isTest && console.log('%c 匹配', 'color:green', list[index].name, list[index])
                return {defaultIndex: [id.toString()], id}
            } else {
                for (let index in list) {
                    let item = list[index];
                    let hasChildren = typeof item.children === 'object' && item.children.length
                    if (hasChildren) {
                        let res = this.forEachDefaultsSelect(path, item.children, level + 1)
                        if (res.id && res.defaultIndex) {
                            res.defaultIndex.unshift(item.id)
                            result = res
                        }
                    }
                    if (result) return result
                }
            }
            console.log(result, 'hsg')
            return result
        },
        // 打开消息通知
        showNotification() {
            // this.$notify({
            //   title: 'HTML 片段',
            //   dangerouslyUseHTMLString: true,
            //   message: '<strong>这是 <i>HTML</i> 片段</strong>',
            //   duration: 0
            // });
        },
        /**
         * 退出登录
         */
        loginOut() {
            this.$confirm('再次访问本站时，需要重新登录。 是否退出?', '退出登录', {
                confirmButtonClass: "el-button--danger",
                confirmButtonText: '退出',
                cancelButtonText: '取消',
                iconClass: 'el-icon-warning-outline',
            }).then((res) => {
                try {
                    this.$store.commit('setToken', '');
                    this.$store.commit('setUserInfo', {});
                    this.$router.push({name: 'login'});
                    return Promise.resolve(res)
                } catch (e) {
                    return Promise.reject(new Error('清空用户数据失败'))
                }
            }).then(res => {
                this.$message({
                    type: 'success',
                    message: '已退出登录!'
                });
            }).catch(() => {

            });
        },
        triggerCollapse() {
            this.collapse = !this.collapse;
            localStorage.setItem('home_menu_has_collapse', this.collapse);
        },
    },
    computed: {
        ...mapState(['userInfo', 'systemName', 'token']),
        ...mapGetters({
            includeList: 'homeRouterTabs/includeList',
        }),
        key() {
            return this.$route.name ? this.$route.name + +new Date() : this.$route + +new Date()
        }
    },
    // 处理点击消息详情跳转
    watch: {
        '$route': function (to, from) {
            this.countMenuListDefaults(to);
        },
    },
    beforeRouteEnter(to, from, next) {
        // 在渲染该组件的对应路由被 confirm 前调用
        // 不！能！获取组件实例 `this`
        // 因为当钩子执行前，组件实例还没被创建
        console.log('beforeRouteEnter', to, from)
        next();
        if (openIframeTabs) store.commit('homeRouterTabs/plusItem', to);
    },
    beforeRouteUpdate(to, from, next) {
        // 在当前路由改变，但是该组件被复用时调用
        // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
        // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
        // 可以访问组件实例 `this`
        console.log('beforeRouteUpdate', from.name, '——>', to.name)
        next();
        if (openIframeTabs) store.commit('homeRouterTabs/plusItem', to);
    },
    beforeRouteLeave(to, from, next) {
        // 导航离开该组件的对应路由时调用
        // 可以访问组件实例 `this`
        console.log('beforeRouteLeave')
        next();
        if (openIframeTabs) store.commit('homeRouterTabs/setList')
    },
}
</script>

<style lang="less" scoped>
@import "../../src/assets/css/data";

@home-header-height: 50px !important;
@home-header-image-height: 30px;
@home-footer-height: 40px;
@home-aside-width: 200px !important;
@home-aside-menu-width: 220px !important;
@home-right-padding-space: 10px;
.home-wrap {
    height: 100%;
}

.home-right {

}

.home-header {
    height: @home-header-height;
    display: flex;
    align-items: center;

    .user-box {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .user-avatar {
            width: @home-header-image-height;
            height: @home-header-image-height;
            margin: 0 10px;
        }

        .user-name {
            font-size: 16px;
            margin-right: 10px;
        }
    }
}

.home-router-wrap,
.home-main {
    padding: 0 @home-right-padding-space;
    margin-top: @home-right-padding-space;
}

.home-router-wrap {
    box-sizing: border-box;
    width: 100%;
}

.home-aside {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &,
    .system-info,
    .home-menu,
    .el-submenu,
    .el-menu-item,
    .el-submenu__title,
    .el-menu--collapse {
        transition: width 0.3s ease;
    }

    .system-info {
        overflow: hidden;
        line-height: @home-header-height;
        height: @home-header-height;
        text-align: center;

        .system-logo {
            vertical-align: middle;
            width: @home-header-image-height;
            height: @home-header-image-height;
            display: inline-block;
            border: none;
            padding: 0;
        }

        .system-name {
            margin: 0;
            font-size: 16px;
            overflow: hidden;
            vertical-align: middle;
            white-space: nowrap;
        }
    }

    &.menu-open {
        width: @home-aside-width;

        .home-menu {
            width: @home-aside-menu-width;
            .hide-scroll-bar;

            .el-submenu {
                width: @home-aside-width;
            }
        }

        .system-info {
            width: @home-aside-width;
        }
    }

    &.menu-close {
        @home-aside-width: 64px !important;
        @home-aside-menu-width: 84px !important;
        width: @home-aside-width;

        .home-menu {
            .hide-scroll-bar;
            width: @home-aside-menu-width;

            .el-submenu {
                width: @home-aside-width;
            }
        }

        .system-info {
            width: @home-aside-width;
        }
    }
}

.home-menu {
    flex: 1;
    /*background-color: #D3DCE6;*/
    overflow-x: hidden;
    overflow-y: scroll;
    border: none;
}

.home-main {
    margin-bottom: @home-right-padding-space;
    overflow-y: hidden;
    height: 100%;
    /*max-height: 100%;*/

    & > * {
        height: 100%;
        overflow-y: auto;

        &.scroll-none {
            overflow-y: hidden;
        }
    }
}

.pages {
    padding: 20px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    background-color: white;
    border-radius: 0 0 6px 6px;

    &.bg-none {
        background-color: transparent;
    }
}

.home-footer {
    background-color: #B3C0D1;
    text-align: center;
    line-height: @home-footer-height;
    font-size: 12px;
    color: #666;
}

.hide-scroll-bar {
    animation: hideScrollBar 0.4s;
    animation-fill-mode: none;
}

@keyframes hideScrollBar {
    0% {
        overflow-y: hidden;
    }
    100% {
        overflow-y: hidden;
    }
}
</style>
<style lang="less">
@import (reference) "../assets/css/data";

.paging-row {
    text-align: center;
    margin: 20px auto;
}

.user-popup {
    width: 100%;
    text-align: center;

    .login-out {
        width: 100%;
    }
}

.style-primary {
    .definedElementBackgroundSystem(@color-primary);
    .definedElementBackgroundHeader(white, #666);
    .definedElementBackgroundMain(#e9e9e9, #666);
    .definedElementBackgroundMenu();
}

.definedElementBackgroundSystem(@background-color,@color:white) {
    .system-info {
        background-color: @background-color;
        color: @color;
    }
}

.definedElementBackgroundHeader(@background-color,@color:white) {
    .home-header {
        background-color: @background-color;
        color: @color;
    }
    .home-router-tabs {
        background-color: @background-color;
        color: @color;
    }
    .home-main {
    }
}

.definedElementBackgroundMain(@background-color,@color:white) {
    .home-right {
        background-color: @background-color;
    }
    .home-main {
        color: @color;
    }
}

.definedElementBackgroundMenu(@background-color:#344058,@color:#fff,@active-color:#ffd04b) {
    .home-aside {
        background-color: @background-color !important;
    }
    .el-menu,
    .el-menu-item,
    .el-submenu__title {
        /*背景颜色*/
        background-color: @background-color !important;
        color: @color;
    }
    .el-submenu__title i {
        color: @color;
    }
    .el-menu-item,
    .el-submenu__title {
        &:focus,
        &:hover {
            background-color: saturate(@background-color, 20%) !important;
        }
    }
    .el-submenu,
    .el-menu-item {
        &.is-active {
            color: @active-color;
        }
    }
    /*二级菜单无效*/
    .el-menu--popup {

    }
    .show-notification {
        display: flex;
        position: relative;
        align-items: center;
        margin-right: 20px;
        padding: 4px
    }
    .show-notification:hover {
        background: #f5f7fa;
        border-radius: 4px;
    }
    .notification-num {
        background: red;
        border: 1px solid #d60000;
        color: #fff;
        font-size: 12px;
        position: absolute;
        border-radius: 50%;
        padding: 2px 4px;
        right: -8px;
        top: -5px;
        text-align: center;
        line-height: 14px;
    }
}
</style>
