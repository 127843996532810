<template>
  <div id="app">
    <browser-hint></browser-hint>
    <router-view/>
  </div>
</template>

<script>
  import browserHint from './components/public/browser/browser'
  export default {
    name:'App',
    components:{
      browserHint,
    }
  }
</script>
<style lang="less">
  html,body,#app{
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  #app{
    min-width: 1180px;
  }
</style>
