// navigator.userAgent.match(/chrome\/(\d+.)/ig)
function browserType(){
  const UA = navigator.userAgent.toLowerCase();
  const testUA = regexp => regexp.test(UA);
  const testVs = regexp => (UA.match(regexp)||[]).toString().replace(/[^0-9|_.]/g, "").replace(/_/g, ".");
  let data = {
    isIE:false,
    isEdge:false,
    isChrome:false,
    isFirefox:false,
    isSafari:false,
    isOpera:false,
  };
  let kernel = ''; // 内核
  let kernelVs = "";// 内核版本
  let browser = ''; // 载体
  let browserVs = "";// 载体版本
  let shell = ""; // 外壳
  let shellVs = "";// 外壳版本

  if (testUA(/applewebkit/g)) {
    kernel = "webkit"; // webkit内核
    if (testUA(/edge/g)) {
      browser = "edge"; // edge浏览器
      data.isEdge = true;
    } else if (testUA(/opr/g)) {
      browser = "opera"; // opera浏览器
      data.isOpera = true;
    } else if (testUA(/chrome/g)) {
      browser = "chrome"; // chrome浏览器
      data.isChrome = true;
    } else if (testUA(/safari/g)) {
      browser = "safari"; // safari浏览器
      data.isSafari = true;
    }
  } else if (testUA(/gecko/g) && testUA(/firefox/g)) {
    kernel = "gecko"; // gecko内核
    browser = "firefox"; // firefox浏览器
    data.isFirefox = true;
  } else if (testUA(/presto/g)) {
    kernel = "presto"; // presto内核
    browser = "opera"; // opera浏览器
    data.isOpera = true;
  } else if (testUA(/trident|compatible|msie/g)) {
    kernel = "trident"; // trident内核
    browser = "iexplore"; // iexplore浏览器
    data.isIE = true;
  }

  if (kernel === "webkit") {
    kernelVs = testVs(/applewebkit\/[\d._]+/g);
  } else if (kernel === "gecko") {
    kernelVs = testVs(/gecko\/[\d._]+/g);
  } else if (kernel === "presto") {
    kernelVs = testVs(/presto\/[\d._]+/g);
  } else if (kernel === "trident") {
    kernelVs = testVs(/trident\/[\d._]+/g);
  }

  if (browser === "chrome") {
    browserVs = testVs(/chrome\/[\d._]+/g);
  } else if (browser === "safari") {
    browserVs = testVs(/version\/[\d._]+/g);
  } else if (browser === "firefox") {
    browserVs = testVs(/firefox\/[\d._]+/g);
  } else if (browser === "opera") {
    browserVs = testVs(/opr\/[\d._]+/g);
  } else if (browser === "iexplore") {
    browserVs = testVs(/(msie [\d._]+)|(rv:[\d._]+)/g);
  } else if (browser === "edge") {
    browserVs = testVs(/edge\/[\d._]+/g);
  }

  if (testUA(/micromessenger/g)) {
    shell = "wechat"; // 微信浏览器
    shellVs = testVs(/micromessenger\/[\d._]+/g);
  } else if (testUA(/qqbrowser/g)) {
    shell = "qq"; // QQ浏览器
    shellVs = testVs(/qqbrowser\/[\d._]+/g);
  } else if (testUA(/ucbrowser/g) || testUA(/ubrowser/g)) {
    shell = "uc"; // UC浏览器
    shellVs = testVs(/ucbrowser\/[\d._]+/g);
  } else if (testUA(/qihu/g) || testUA(/360se/g)) {
    shell = "360"; // 360浏览器(无版本)
  } else if (testUA(/2345explorer/g)) {
    shell = "2345"; // 2345浏览器
    shellVs = testVs(/2345explorer\/[\d._]+/g);
  } else if (testUA(/metasr/g)) {
    shell = "sougou"; // 搜狗浏览器(无版本)
  } else if (testUA(/lbbrowser/g)) {
    shell = "liebao"; // 猎豹浏览器(无版本)
  } else if (testUA(/maxthon/g)) {
    shell = "maxthon"; // 遨游浏览器
    shellVs = testVs(/maxthon\/[\d._]+/g);
  }
  return Object.assign({
    kernel,
    kernelVs,
    browser,
    browserVs,
    shell,
    shellVs,
  },data);
}

export const browser = browserType();

