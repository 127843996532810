<template>
  <el-tabs class="message-wrap" v-loading="loading" @tab-click="switchTab">
    <el-tab-pane>
      <span slot="label">订单消息<i class="num-tag" v-if="messageNumData.orderUnreadMsgNum != 0">{{messageNumData.orderUnreadMsgNum}}</i></span>
      <!-- <el-button @click="allReadBtn(1)" v-if="messageNumData.orderUnreadMsgNum != 0" style="margin-bottom:10px" size="mini" type="warning" plain round>清除未读</el-button> -->
      <ul
        v-if="orderInformationlist.length != 0"
        class="infinite-list"
        v-infinite-scroll="load1"
        style="overflow: auto;"
      >
        <li
          v-for="(item, index) in orderInformationlist"
          class="infinite-list-item"
          :key="index"
        >
          <el-row>
            <el-col :span="13" class="msg-title">{{ item.msgTitle }}</el-col>
            <el-col :span="11" class="create-time-text">{{
              item.createTime_text
            }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="item.msgBody_obj.msgImage ? 6 : 0">
              <img
                width="60px"
                :src="item.msgBody_obj.msgImage"
              />
            </el-col>
            <el-col :span="item.msgBody_obj.msgImage ? 18 : 24">
              <el-row>
                <el-col class="msg-title" :span="24">{{
                  item.msgBody_obj.msgTitle
                }}</el-col>
              </el-row>
              <el-row
                class="msg-body-list"
                v-for="(item1, index1) in item.msgBody_obj.msgBodyList"
                :key="index1"
              >
                <el-col :span="6">{{ item1.key }}</el-col>
                <el-col :span="18">{{ item1.value }}</el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="detail-wrap" >
            <el-col :span="23" >
                <div @click="deltailBtn(item)">详情</div>
            </el-col>
            <el-col :span="1">></el-col>
          </el-row>
        </li>
      </ul>
      <div v-else class="no-data">暂无数据</div>
    </el-tab-pane>
    <el-tab-pane>
      <span slot="label">店铺消息<i class="num-tag" v-if="messageNumData.storeUnreadMsgNum != 0">{{messageNumData.storeUnreadMsgNum}}</i></span>
      <!-- <el-button @click="allReadBtn(2)" v-if="messageNumData.storeUnreadMsgNum != 0" style="margin-bottom:10px" size="mini" type="warning" plain round>清除未读</el-button> -->
      <ul
        v-if="shopInformationlist.length != 0"
        class="infinite-list"
        v-infinite-scroll="load2"
        style="overflow: auto"
      >
        <li
          v-for="(item, index) in shopInformationlist"
          class="infinite-list-item"
          :key="index"
        >
          <el-row>
            <el-col :span="13" class="msg-title">{{ item.msgTitle }}</el-col>
            <el-col :span="11" class="create-time-text">{{
              item.createTime_text
            }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="item.msgBody_obj.msgImage ? 6 : 0">
              <img
                width="60px"
                :src="item.msgBody_obj.msgImage"
              />
            </el-col>
            <el-col :span="item.msgBody_obj.msgImage ? 18 : 24">
              <el-row>
                <el-col class="msg-title" :span="24">{{
                  item.msgBody_obj.msgTitle
                }}</el-col>
              </el-row>
              <el-row
                class="msg-body-list"
                v-for="(item1, index1) in item.msgBody_obj.msgBodyList"
                :key="index1"
              >
                <el-col :span="6">{{ item1.key }}</el-col>
                <el-col :span="18">{{ item1.value }}</el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="detail-wrap" >
            <el-col :span="23" >
                <div @click="deltailBtn(item)">详情</div>
            </el-col>
            <el-col :span="1">></el-col>
          </el-row>
        </li>
      </ul>
      <div v-else class="no-data">暂无数据</div>
    </el-tab-pane>
    <el-tab-pane>
      <span slot="label">平台通知<i class="num-tag" v-if="messageNumData.platformUnreadMsgNum != 0">{{messageNumData.platformUnreadMsgNum}}</i></span>
      <!-- <el-button @click="allReadBtn(5)" v-if="messageNumData.platformUnreadMsgNum != 0" style="margin-bottom:10px" size="mini" type="warning" plain round>清除未读</el-button> -->
      <ul
        v-if="terraceInformationlist.length != 0"
        class="infinite-list"
        v-infinite-scroll="load3"
        style="overflow: auto"
      >
        <li
          v-for="(item, index) in terraceInformationlist"
          class="infinite-list-item"
          :key="index"
        >
          <el-row>
            <el-col :span="13" class="msg-title">{{ item.msgTitle }}</el-col>
            <el-col :span="11" class="create-time-text">{{
              item.createTime_text
            }}</el-col>
          </el-row>
          <el-row>
              <el-col :span="24">
                <img
                    width="100%"
                    :src="item.msgBody_obj.msgImage"
                />
                </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-row
                class="msg-body-list"
                v-for="(item1, index1) in item.msgBody_obj.msgBodyList"
                :key="index1"
              >
                <el-col :span="24">{{ item1.value }}</el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="detail-wrap" >
            <el-col :span="23" >
                <div @click="platformNotificationDetails(item)">详情</div>
            </el-col>
            <el-col :span="1">></el-col>
          </el-row>
        </li>
      </ul>
      <div v-else class="no-data">暂无数据</div>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import { URL } from "../../../config";
import { parseTimeArray, JSONParseArray } from "../../../assets/js/utils";
export default {
  name: "messageNotification",
  props: ["messageNumData"],
  data() {
    return {
      orderInformationlist: [], // 订单消息列表
      shopInformationlist: [], //店铺消息列表
      terraceInformationlist: [], // 平台消息列表
      loading: false, // 加载
      params: {
        userClientType: 31,
        msgType: 1,
      },
      pageNo1: 1,
      pageNo2: 1,
      pageNo3: 1
    };
  },
  created() {
    this.getList(1, "orderInformationlist", 1);
    this.getList(2, "shopInformationlist", 1);
    this.getList(5, "terraceInformationlist", 1);
  },
  methods: {
    getList(tag, listName, pageNo) {
      // let self = this;
      // self.loading = true;
      // return self.axios
      //   .get(URL.supplierInform.getMessageServiceNoticeList, {
      //     params: {
      //       userClientType: 31,
      //       msgType: tag,
      //       pageNo: pageNo
      //     },
      //   })
      //   .then((res) => {
      //     self.loading = false;
      //     parseTimeArray(res.data.records, ["createTime"]);
      //     JSONParseArray(res.data.records, ["msgBody"]);
      //     self[listName] = self[listName].concat(res.data.records);
      //   })
      //   .catch((res) => {
      //     self.loading = false;
      //     self[listName] = [];
      //   });
    },
    load1() {
      this.pageNo1 += 1
      this.getList(1, "orderInformationlist", this.pageNo1);
    },
    load2() {
      this.pageNo2 += 1
      this.getList(2, "orderInformationlist", this.pageNo1);
    },
    load3() {
      this.pageNo3 += 1
      this.getList(5, "orderInformationlist", this.pageNo1);
    },
    // 清除未读
    allReadBtn(tag) {
        this.$emit('setMsgHaveRead', tag)
    },
    switchTab(data) {
        console.log(data.index)
        if (data.index == 1 && this.messageNumData.storeUnreadMsgNum != 0) {
            this.$emit('setMsgHaveRead', 2)
        }
        if (data.index == 2 && this.messageNumData.platformUnreadMsgNum != 0) {
            this.$emit('setMsgHaveRead', 5)
        }
    },
    // 查看详情
    deltailBtn(value) {
        if (0 < value.subMsgType && value.subMsgType < 3) {
            console.log('订单详情')
            this.$router.push({
                path:'/orderDetail',
                query:{
                    orderId:value.jumpSource,
                }
            });
        } else if ( 2 < value.subMsgType && value.subMsgType < 10) {
            console.log('售后详情')
            this.$router.push({
                path:'/orderRefundDetail',
                query:{
                    refundId:value.jumpSource,
                }
            });
        } else if ( 100 < value.subMsgType && value.subMsgType < 105) {
            console.log('流水详情')
            this.$router.push({
                path:'/supplierSettleDetail',
                query:{
                    orderId:value.jumpSource,
                }
            });
        } else if ( 104 < value.subMsgType && value.subMsgType < 107) {
            console.log('商品审核列表')
            this.$router.push({
                path:'/supplierGoodsReview'
            });
        } else if ( 106 < value.subMsgType && value.subMsgType < 109) {
            console.log('品牌审核列表')
            this.$router.push({
                path:'/supplierBrand'
            });
        } else if ( 108 < value.subMsgType && value.subMsgType < 110) {
            console.log('库存列表')
            this.$router.push({
                path:'/supplierStockList'
            });
        } else if ( 108 < value.subMsgType && value.subMsgType < 110) {
            console.log('库存列表')
            this.$router.push({
                path:'/supplierStockList'
            });
        }
    },
    // 查看平台消息
    platformNotificationDetails(value) {
        this.$router.push({
            path: '/platformNotificationDetails',
            query:{
                id:value.jumpSource
            }
        })
    }
  },
};
</script>
<style lang="less" scoped>
.message-wrap {
  height: 730px;
}
ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
.infinite-list {
  height: 620px;
}
.num-tag {
  background: red;
  color: #fff;
  border-radius: 50%;
  padding: 0 6px 0 2px;
  font-size: 12px;
  text-align: center;
  position: relative;
  bottom: 5px;
}
.no-data {
  text-align: center;
  margin-top: 300px;
}
.infinite-list-item {
  padding: 20px;
  border-radius: 4px;
  background: #f5f7fa;
  margin-bottom: 10px;
  .msg-title {
    font-weight: 600;
    margin-bottom: 10px;
  }
  .create-time-text {
    font-size: 12px;
    color: #777;
    margin-bottom: 10px;
  }
  .msg-body-list {
    font-size: 12px;
    color: #777;
    padding: 2px 0;
  }
}
.detail-wrap {
    padding: 4px;
    border-top: 1px solid #dadada;
    margin-top: 10px;
    color: #3a8ee6;
    cursor: pointer;
    border-radius: 4px;
}
.detail-wrap:hover{
    background: #ecf5ff;
}
</style>
