import dayjs from 'dayjs'
export function stringToBoolean(str = ''){
  if(typeof str !== 'string') return Boolean(str);
  switch (str.toLowerCase()) {
    case "true":
    case "yes":
    case "1":
      return true;
    case "false":
    case "no":
    case "0":
      return false;
    default:
      return Boolean(str);
  }
}
export function objectRemoveEmpty (data) {
  let keys = Object.keys(data).filter(res=>empty(data[res]))
  keys.forEach(key=>{
    delete data[key]
  })
  function empty(res){
    if(res === 0) return false
    return isEmpty(res)
  }
  return data
}

export const miniToolbarOptionsVideo = [
	['bold',], // 加粗
	[{ header: 1 }], // 标题，键值对的形式；1、2表示字体大小
	[{ direction: 'rtl' }], // 文本方向
	[{ align: [] }], // 对齐方式
	['clean'], // 清除字体样式
	['image'],
	['video']
]

export function isNormalLongId(val) {//判断是否后端返回的ID
  if(typeof val === 'number'){
    return val > 100000;
  }
  if(typeof val === 'string'){
    return parseFloat(val) > 100000;
  }
  return false;
}

export const isEmpty = function(val) {
  // null or undefined
  if (val == null) return true;

  if (typeof val === 'boolean') return false;

  if (typeof val === 'number') return !val;

  if (val instanceof Error) return val.message === '';

  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !val.length;

    // Map or Set or File
    case '[object File]':
    case '[object Map]':
    case '[object Set]': {
      return !val.size;
    }
    // Plain Object
    case '[object Object]': {
      return !Object.keys(val).length;
    }
  }

  return false;
};
export const isObject = function(target){
  return typeof target === 'object' && target !== null
}
export const isObjectPure = function(target){
  return isObject(target) && !(target instanceof Array)
}
export const isArray = function(target,judgeLength = false){
  let judge = target instanceof Array;
  if(judgeLength && judge){
    judge = !!Array.length;
  }
  return judge;
}
export function isStringNumber(num){
  if(!num && num !== 0) return false;
  num = parseFloat(num);
  if(isNaN(num)) return false;
  return true;
}
export function priceToFixed(num,len = 2){
  return parseFloat(num).toFixed(len);
}
export function toNumberPrice(num,len = 2){
  if(num || num === 0){
    num = parseFloat(num);
    if(isNaN(num)) return '';
    num = parseFloat(num.toFixed(len));
    return num;
  }
  return ''
}
export function toInteger(num){
  if(num){
    num = parseInt(num);
    if(isNaN(num)) return '';
    return num;
  }
  return ''
}
export const typeOfEqual = function(target,data){
  if(typeof target !== typeof data) return false;
  if(typeof target === 'object'){
    if(isArray(target)){
      if(!isArray(data)) return false;
    }
    if(target === null){
      if(data === null) return false;
    }
  }
  return true
}
export const isHTMLElement = function(val){
  return val instanceof HTMLElement
}
export function isNumber(num){
  return typeof num === 'number' && !isNaN(num)
}
/**
 * 引用与非引用值 深拷贝方法
 * @param data
 */
export function deepsClone(data) {
  if (typeof data !== 'object' || typeof data === 'function' || data === null) {
    return data
  }

  let item
  if (Array.isArray(data)) {
    item = []
  }

  if (!Array.isArray(data)) {
    item = {}
  }

  for (let i in data) {
    if (Object.prototype.hasOwnProperty.call(data, i)) {
      item[i] = deepsClone(data[i])
    }
  }

  return item
}
/**
 * assign 深拷贝方法
 * @param data
 */
export function deepsAssign(target,...data) {
  if (typeof target !== 'object' || target === null) return target;
  data.forEach(res=>{
    target = deepsAssignOnce(target,res);
  })
  return target
}
export function deepsAssignOnce(target,data){
  if (typeof target !== 'object' || target === null) return target;
  if(target instanceof Array && !(data instanceof Array)) return target;
  for(let i in data){
    if (typeof data[i] !== 'object' || data[i] === null){
      target[i] = data[i];
    }else{
      let t = target[i];
      let d = data[i];
      if(isArray(d)){
        if(!isArray(t)){
          target[i] = []
        }
      }else{
        if(!isObjectPure(t)){
          target[i] = {}
        }
      }
      target[i] = deepsAssignOnce(target[i],data[i])
    }
  }
  return target
}
/**
 * 处理搜索数据
 * @param data
 */
export function disposeSearchData(data){
  if(data.searchType && data.searchValue){
    data[data.searchType] = data.searchValue
  }
  if(data.searchType) delete data.searchType
  if(data.searchValue) delete data.searchValue
  objectRemoveEmpty(data);
  return data
}

/**
 * 解析时间戳为文本
 * @param time
 * @param style
 * @returns {string}
 */
export function parseTime(time,style = 'YYYY-MM-DD HH:mm:ss'){
  if(typeof time === 'string' && !isEmpty(time)){
    let str = parseInt(time).toString();
    if([9,13].includes(str.length)) time = parseInt(time);
  }
  if([0,'0','',undefined,null].includes(time)) return '';
  return dayjs(time).format(style)
}
export function parseTimeObject (obj = {},keys = ['updateTime'],style) {
  if(typeof obj !== 'object') return obj
  keys.forEach(res=>{
    let time = obj[res]
    if(time) obj[res + '_text'] = parseTime(time,style)
  })
  return obj
}
export function parseTimeArray (obj = [],keys,style) {
  if(!(obj instanceof Array)) return obj
  obj.forEach(res=>{
    if(typeof res === 'object') parseTimeObject(res,keys,style)
  })
  return obj
}

// 时间戳转换
const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

export function formatTime(date) {
  date = new Date(Number(date));
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();

  return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':');
};

export function getNextDayTimestamp (time) {
  time =time + 24*3600*1000;
  return time;
}

export function arrayToObject(arr=[],idKey='id',valueKey='name'){
  let obj = {};
  arr.forEach(res=>{
    obj[res[idKey]] = res[valueKey];
  });
  return obj;
}

/**
 * 将一个对象从另一个对象中也拥有的值copy一份
 * @param target
 * @param options
 * @param keys
 */
export function copyObjectNeedValue(target={},options={},keys=[]){
  if(isEmpty(keys)){
    keys = Object.keys(target);
  }
  keys.forEach(key=>{
    let value = options[key];
    if(!isEmpty(value) || value === 0) {
      target[key] = value;
    }
    // }else{
    //   let before = target[key];
    //   if(typeof before === 'string' ){
    //     target[key] = '';
    //   }else if(typeof before === 'number' ){
    //     target[key] = 0;
    //   }else if(typeof before === 'object' ){
    //     if(before instanceof Array){
    //       target[key] = [];
    //     }else{
    //       target[key] = null;
    //     }
    //   }else{
    //     target[key] = undefined;
    //   }
    // }
  });
  return target;
}

/**
 * 富文本工具栏配置
//  */
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
  ['blockquote', 'code-block'],     //引用，代码块
  [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
  [{ 'script': 'sub'}, { 'script': 'super' }],   // 上下标
  [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
  [{ 'direction': 'rtl' }],             // 文本方向
  [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
  [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
  [{ 'font': [] }],     //字体
  [{ 'align': [] }],    //对齐方式
  ['clean'],    //清除字体样式
  ['image','video']    //上传图片、上传视频
];
export default toolbarOptions;

export function JSONParse(d){
  try {
    return JSON.parse(d);
  }catch (e) {
    return '';
  }
}
export function JSONStringify(d){
  try {
    return JSON.stringify(d);
  }catch (e) {
    return '';
  }
}
export function JSONClone (d) {
  try {
    return JSON.parse(JSONStringify(d));
  }catch (e) {
    return '';
  }
}
// 把列表中的json类型转为对象
export function JSONParseArray (obj = [],keys) {
  if(!(obj instanceof Array)) return obj
  obj.forEach(res=>{
    if(typeof res === 'object') {
      keys.forEach(res1=>{
        let time = res[res1]
        if(time) {
          res[res1 + '_obj'] = JSON.parse(time)
        }
      })
    }
  })
  return obj
}

/**
 * 对比前后两个对象是否匹配
 * @param before
 * @param after
 * @param originName
 * @returns {boolean}
 */
export function matchingTwoObject(before,after,originName = ''){
  let beforeList = JSON.stringify(before);
  let afterList = JSON.stringify(after);
  let judge = beforeList === afterList;
  if(originName) console.log(`${originName} matchingTwoObject()匹配`,judge ? '相等' : '不相等');
  return judge;
}

export function parseHtml(text){
  if(!text) return '';
  let html = text.split('\n');
  html = html.map(res=>{
    if(res){
      return `<p>${res||''}</p>`
    }else{
      return `<p style="height:18px;"></p>`
    }
  });
  return html.join('');
}
export function JSONParseImages(text){
  let list = []
  if(!text) return list;
  try {
    list = JSON.parse(text)
  }catch (e) {}
  return list
}

export function goBack(){
  this.$router.go(-1);
}
export function goHome(){
  this.$router.replace({
    name:'index',
  });
}
export function hasOssImagesParams(url) {
  if(url){
    return /\?x-oss-process=/ig.test(url)
  }
  return false
}
export function ossImagesMFit(url,l=100){
  if(url){
    if(hasOssImagesParams(url)){
      return url + `,image/resize,l_${l},m_mfit`;
    }else{
      return url + `?x-oss-process=image/resize,l_${l},m_mfit`;
    }
  }else{
    return url
  }
}
export function ossImagesCircle(url,r=100){
  if(url){
    if(hasOssImagesParams(url)){
      return url + `,image/circle,r_${r}`;
    }else{
      return url + `?x-oss-process=image/circle,r_${r}`;
    }
  }else{
    return url
  }
}

export function getTimeTypeArray(){
  return [
    {id:'1',name:'自然日',lastTimeDesc:"前一日",value:dayjs().startOf('day').subtract(1,'day').format('YYYY-MM-DD'),type:'date',format:'yyyy-MM-dd',option:{firstDayOfWeek: 1,disabledDate(time) {return time.getTime() >= dayjs().startOf('day').valueOf();}, }},
    {id:'2',name:'自然周',lastTimeDesc:"前一周",value:dayjs().subtract(1,'week').startOf('week').add(2,'day').format('YYYY-MM-DD'),type:'week',format:'yyyy 第 WW 周',option:{firstDayOfWeek: 1,disabledDate(time) {return time.getTime() > dayjs().startOf('week').valueOf();},}},
    {id:'3',name:'自然月',lastTimeDesc:"前一月",value:dayjs().startOf('month').subtract(1,'month').format('YYYY-MM-DD'),type:'month',format:'yyyy年MM月',option:{firstDayOfWeek: 1,disabledDate(time) {return time.getTime() >= dayjs().startOf('month').valueOf();},}},
  ];
}


/**
 * element 表单多个验证不通过，滚动到验证提示的位置
 * { String } object 验证规则
 * 备注：
 *     1.this.$refs.infoList.validate((_, object)=>{})   返回两个参数，第一个参数：验证是否成功，第二个参数：验证规则
 *     2.验证的标签上添加ref属性，名字和prop值一致。例：<el-form-item :prop="'infoData.' + scope.$index + '.coalName'" :ref="'infoData.' + scope.$index + '.coalName'" ></el-form-item>
 */
export function scrollToView(_this, object) {
  for (let i in object) {
    let dom = _this.$refs[i];
    if (Object.prototype.toString.call(dom) !== "[object Object]") {
      //这里是针对遍历的情况（多个输入框），取值为数组
      dom = dom[0];
    }
    //第一种方法（包含动画效果）
    dom.$el.scrollIntoView({
      //滚动到指定节点
      block: "center", //值有start,center,end，nearest，当前显示在视图区域中间
      behavior: "smooth", //值有auto、instant,smooth，缓动动画（当前是慢速的）
    });

    //第二种方法
    // let top = dom.$el.getBoundingClientRect().top; //获取当前节点的偏移值
    // console.log(top,'top');
    // let scrollTop = document.documentElement.scrollTop; //获取视图滚动值
    // let diff = top + scrollTop;
    // document.documentElement.scrollTop = diff - 276; //276是第一个输入框节点距离顶部的偏移值，也可以在初始化提前保存
    //window.scrollTo(0,diff- 276) //同上
    break; //因为我们只需要检测一项,所以就可以跳出循环了
  }
}
