import {templateElDialog} from "../template-el-dialog";
import {URL,PATH_LOGO_STORE,PROJECT_NAME} from "../../../../config";
import {drawBeforeImages,canvasSaveImageBase64,downImageBase64} from "../../../../assets/js/downImage";
import {ossImagesMFit,ossImagesCircle} from "../../../../assets/js/utils";

function returnBaseInfo(){
  return {
    userLogo: '',
    userName: "",
    inviteCode: '',
  }
}
let canvasIdNumber = 0;
export let canvasQrBase =  {
  // name: "canvasQr",
  mixins:[templateElDialog],
  props:{
    title: {
      type: String,
      default: '推广码'
    },
    width: { //	Dialog 的宽度
      type: String,
      default: '400px',
    },
    showHint: { //	显示日志
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Object,
      default:function(){
        return returnBaseInfo();
      }
    },
  },
  data(){
    return {
      loading:false,
      /* 是否在显示时绘制 */
      showStartDraw:true,
      /*canvas对象*/
      canvasId:`canvas_${new Date().getTime()}_${++canvasIdNumber}`,
      canvas:null,
      ctx:null,
      size:{
        width:600,
        height:940,
        paddingLeft:30,
      },
      base64:'',
      /*hint*/
      hintArray:[],
      /*准备图片地址*/
      filePaths:{
        user:'',
        qr:'',
        goods:'',
      },
    }
  },
  created(){},
  mounted() {},
  methods:{
    ossImagesMFit,
    ossImagesCircle,
    /**
     * 记录日志
     * @param text
     */
    pushHint(text= ''){
      if(text){
        if(this.showHint) console.log(`%c hint【${this.hintArray.length}】：`,'color:red;',...arguments);
        this.hintArray.push(text);
      }
    },
    isErrorText(text=''){
      let judgeSuccess = /(success)|(\u5b8c\u6210)|(\u6210\u529f)/ig.test(text);
      let judgeError = /(error)|(fail)|(\u9519\u8bef)|(\u51fa\u9519)/ig.test(text);
      if(judgeSuccess){
        return 'color-primary'
      }else if(judgeError){
        return 'color-danger'
      }else {
        return ''
      }
    },
    /**
     * 判断绘制条件
     */
    judgeDrawPrecondition(){
      this.pushHint('判断绘制前提条件');
      this.$nextTick(()=>{
        this.createCanvas();
      });
    },
    /**
     * 创建canvas
     */
    createCanvas(){
      this.pushHint('获取 canvas对象');
      let canvas = document.getElementById(this.canvasId);
      if (typeof canvas.getContext !== 'function') return this.pushHint('canvas.getContext no\'t function');
      let ctx = canvas.getContext('2d');
      ctx.clearRect(0,0,this.size.width,this.size.height);
      canvas.setAttribute('width',this.size.width);
      canvas.setAttribute('height',this.size.height);
      ctx.save();
      this.canvas = canvas;
      this.canvasDrawStart(ctx);
      this.saveBase64(canvas);
    },
    /**
     * 绘制过程
     * @param ctx
     */
    canvasDrawStart(ctx){
      this.pushHint('绘制 开始');
      this.drawBg(ctx);
      this.drawTitle(ctx);
      this.pushHint('绘制 success');
    },
    drawBg(ctx){
      this.pushHint('绘制 背景');
      ctx.fillStyle = "white";
      ctx.fillRect(0,0,this.size.width,this.size.height);
      ctx.restore();
    },
    drawTitle(ctx){
      this.pushHint('绘制 标题');
      let x = this.size.width/2;
      let y = 130;
      ctx.font = '48px PingFangSC-Semibold, PingFang SC';
      ctx.fillStyle = '#666666';
      ctx.textAlign = 'center';
      ctx.fillText(  '绘制测试内容',x,y);
      ctx.restore();
    },
    /**
     * canvas生成base64数据
     */
    saveBase64(canvas){
      this.pushHint('准备 测试推广码');
      canvasSaveImageBase64(canvas,'png',url=>{
        this.pushHint('生成 测试推广码 success');
        this.base64 = url;
      });
    },
    /**
     * canvas生成图片并下载
     */
    downImage(){
      if(this.base64){
        this.pushHint('下载 测试推广码');
        let name = this.setDownImageName();
        if(name) name += new Date().getTime();
        downImageBase64(this.base64,name);
      }else{
        this.pushHint('下载 测试推广码 error ：base64 is undefined');
      }
    },
    /**
     * 下载图片生成的名字
     * @returns {string}
     */
    setDownImageName(){
      return '测试图片';
    },
    /**
     * 清除绘制相关参数
     */
    clearDrawParams(functionName){
      this.pushHint('清除 绘制相关参数' + functionName||'');
      this.base64 = '';
      this.hintArray = [];
      this.filePaths = {
        user:'',
        qr:'',
        goods:'',
      };
    },
  },
  computed:{
    dialogShow(){
      return this.visibleValue;
    },
  },
  watch:{
    dialogShow(newValue){
      console.log('watch dialogShow',newValue);
      if(!newValue) return false;
      if(this.showStartDraw){
        this.pushHint('更新 重新显示',JSON.stringify(newValue));
        this.judgeDrawPrecondition();
      }
    },
    userInfo(newValue){
      console.log('watch userInfo');
      this.clearDrawParams('userInfo');
    },
  },
}
