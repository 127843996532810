import { deepsClone } from '../assets/js/utils'

/**
 * 判断路由列表中是否已含有判断的值
 * @param list
 * @param item
 * @returns {boolean}
 */
function judgeIncludeRoute(list = [],item = {}){
  let judge = list.some((res,i)=>{
    return res.name === item.name;
  })
  return list.includes(item);
}

/**
 * 判断路由列表中是否已含有判断的值并返回对应索引
 * @param list
 * @param item
 * @returns {number}
 */
function judgeIndexOfRoute(list = [],item = {}){
  let index = -1;
  let judge = list.some((res,i)=>{
    let matching =  res.name === item.name;
    if(matching) index = i;
    return matching
  })
  return index
}

/**
 * 将可能带参url截取?号之前的url并返回
 * @param route
 * @returns {*}
 */
function splitRouteUrl(route){
  if(typeof route != 'string') return route;
  let arr = route.split('?');
  return arr[0]
}

const homeRouterTabs = {
  namespaced: true,
  state: () => ({
    list:[
      {
        fullPath:'/index',
        hash:'',
        meta:{
          title:'首页'
        },
        name:'index',
        params:{},
        query:{},
        closable:false,
      }
    ],
    name:'index',
  }),
  mutations: {
    plusItem(state, originTo){
      let to = {}
      try {
        to = {
          fullPath:originTo.fullPath,
          hash:originTo.hash,
          meta:Object.assign({},originTo.meta),
          name:originTo.name,
          params:Object.assign({},originTo.params),
          query:Object.assign({},originTo.query),
          closable:originTo.name === 'index'?false:true,
        }
      }catch (e) {
        to = originTo;
      }
      let list = state.list;
      let index = judgeIndexOfRoute(list,to);
      if(index<0){
        list.push(to);
      }else{
        list[index] = to;
      }
      this.commit('homeRouterTabs/setName',to.name);
    },
    clearItem(state, originTo){
      let list = state.list;
      let index = judgeIndexOfRoute(list,originTo);
      if(index<0) return false;
      if(originTo.name === state.name){
        let newIndex = -1;
        if(index< list.length - 1){
          newIndex = index + 1;
        }else if(index>0){
          newIndex = index - 1;
        }
        if(newIndex>=0){
          let item = list[newIndex];
          this.commit('homeRouterTabs/setName',item.name);
        }else {
          this.commit('homeRouterTabs/setName');
        }
      }
      this.commit('homeRouterTabs/setList',list.filter(res=>res.name !== originTo.name));
    },
    clearList(state, data){

    },
    setList(state,list = []){
      state.list = list;
    },
    setName(state,name = ''){
      state.name = name;
    }
  },
  actions: {

  },
  getters: {
    includeList:function(state){
      let list = state.list.filter(res=>{
        return res.meta.keepAlive;
      }).map(res=>{
        return res.name;
      })
      console.log('缓存列表',list);
      return list;
    }
  }
}

export default  homeRouterTabs;
