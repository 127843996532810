export let templateElDialog = {
  props:{
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    fullscreen: { // 是否为全屏 Dialog
      type: Boolean,
      default: false
    },
    modal: { // 是否需要遮罩层
      type: Boolean,
      default: true
    },
    modalAppendToBody: { // 遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上
      type: Boolean,
      default: true
    },
    appendToBody: { // Drawer 自身是否插入至 body 元素上。嵌套的 Drawer 必须指定该属性并赋值为 true
      type: Boolean,
      default: true
    },
    customClass: { // Drawer 的自定义类名
      type: String,
      default: ''
    },
    closeOnClickModal: { // 是否可以通过点击 modal 关闭 Dialog
      type: Boolean,
      default: true
    },
    closeOnPressEscape: { // 是否可以通过按下 ESC 关闭 Drawer
      type: Boolean,
      default: false
    },
    showClose: { // 是否显示关闭按钮
      type: Boolean,
      default: true
    },
    beforeClose: { //关闭前的回调，会暂停 Drawer 的关闭
      type: Function,
    },
    destroyOnClose: { // 关闭时销毁 Dialog 中的元素
      type: Boolean,
      default: false
    },
    width: { //	Dialog 的宽度
      type: String,
      default: '50%',
    },
    closed: {
      type: Function,
      default:function(){},
    },
  },
  data(){
    return {
      visibleValue:false,
    }
  },
  created() {
    this.visibleValue = !!this.visible;
  },
  methods:{
    closedCallback(){
      if(typeof this.closed === 'function') this.closed();
      this.$emit('closed');
    }
  },
  watch:{
    visible(newValue){
      console.log('watch visible', newValue)
      this.visibleValue = newValue
    },
    visibleValue(newValue) {
      console.log('watch visibleValue', newValue)
      this.$emit('update:visible', newValue)
    },
  }
}
