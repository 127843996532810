<template>
  <div class="home-router-tabs-wrap">
    <!--<div class="home-router-back">-->
      <!--<el-icon class="el-icon-arrow-left"></el-icon>-->
      <!--返回-->
    <!--</div>-->
    <div class="home-router-tabs">
      <el-tabs :value="routeListName"
               @tab-remove="removeTab"
               :before-leave="triggerRouteTabsBefore"
               @tab-click="triggerRouteTabs">
        <el-tab-pane
          v-for="(item, index) in routeList"
          :key="item.name"
          :label="item.meta.title"
          :name="item.name"
          :closable="item.closable"
        >
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  export default {
    name: 'homeRouterTabs',
    data(){
      return {
        /*router选项卡*/
        /*跳转计时*/
        routerTimeOut:null,
      }
    },
    methods:{
      removeTab(targetName) {
        let activeName = this.routeListName;
        let item = this.findNameItem(targetName);
        if(!item) return false;
        this.$store.commit('homeRouterTabs/clearItem',item)
      },
      triggerRouteTabsBefore(activeName, oldActiveName){
      },
      triggerRouteTabs(activeName, oldActiveName){
        let currentName = activeName.name
        if(currentName === this.name) return false;
        let item = this.findNameItem(currentName);
        if(!item) return false;
        this.$store.commit('homeRouterTabs/setName',currentName)
      },
      findNameItem(name=''){
        let list = this.routeList.filter(res=>res.name === name);
        if(list.length) return list[0];
        return false;
      },
      triggerRouteGoTo(item){
        let data = {
          path:item.fullPath,
          // name:item.name,
          query:item.query,
          params:item.params,
        };
        this.$router.push(data);
      },
    },
    computed:{
      ...mapState('homeRouterTabs',{
        routeList:'list',
        routeListName:'name',
      }),
    },
    watch:{
      routeListName:function(newVal){
        clearTimeout(this.routerTimeOut);
        this.routerTimeOut = setTimeout(()=>{
          if(this.$route.name === newVal) return false;
          let item = this.findNameItem(newVal);
          if(!item) return false;
          this.triggerRouteGoTo(item);
        },200);

      },
    }
  }
</script>

<style scoped lang="less">
  @import "../../../assets/css/data";
  .home-router-tabs-wrap{
    border-radius: 6px 6px 0 0;
    display: flex;
    align-items: center;
    height: 40px;
    /*padding: 0 @padding-primary;*/
    background-color: white;
    overflow: hidden;
    border-bottom: 1px solid #f2f2f2;
    .home-router-back{
      padding: 0 10px;
    }
    .home-router-tabs{
      flex: 1;
      overflow: hidden;
      padding: 0 10px;
    }
  }
</style>
<style lang="less">
  @import "../../../assets/css/data";
  .home-router-tabs-wrap{
    .home-router-tabs{
      .el-tabs__header{
        margin-bottom: 0;
      }
      .el-tabs__nav-wrap{
        &:after{
          content: none;
        }
      }
      .el-tabs__active-bar{
        /*display: none;*/
      }
      .el-tabs__nav-scroll{
        padding: 0 10px;
      }
      .el-tabs__nav-prev,
      .el-tabs__nav-next{
        font-size: 20px;
        line-height: 40px;
      }
      .el-tabs__item{
        padding: 0 10px;
      }
    }
  }
</style>
