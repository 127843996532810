import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import routerChildren from './router.supplier.js'
Vue.use(VueRouter)
let routerList = routerChildren;
const routes = [
  {
    path: '/',
    redirect: { name: 'index' },
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/index',
        name: 'index',
        meta: { title: '首页' },
        component: () => import('../views/index/index.vue'),
      },
      ...routerList
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录' },
    component: () => import('../views/login/login.vue')
  },
  {
    path: '*',
    redirect: { name: 'login' },
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
