<template>
  <el-dialog :title="title"
             :visible.sync="visibleValue"
             :fullscreen="fullscreen"
             :modal="modal"
             :modal-append-to-body="modalAppendToBody"
             :append-to-body="appendToBody"
             :custom-class="customClass"
             :close-on-click-modal="closeOnClickModal"
             :close-on-press-escape="closeOnPressEscape"
             :show-close="showClose"
             :before-close="beforeClose"
             :destroy-on-close="destroyOnClose"
             :width="width"
             @closed="closed">
    <div class="draw-canvas-qr draw-store-qr">
      <div class="draw-qr-box" v-loading="!base64">
        <canvas :id="canvasId"></canvas>
      </div>
      <div class="hint-list" v-if="showHint">
        <div class="hint-text" :class="[isErrorText(item)]" v-for="(item,index) in hintArray">【{{index}}】{{item}}</div>
      </div>
      <div class="action">
        <el-button type="primary"
                   @click="downImage"
                   :disabled="!base64">下载图片</el-button>
      </div>
      <div class="hint-one">
        <div class="hint-text" :class="[isErrorText(hintArray[hintArray.length - 1])]" v-if="hintArray.length">【{{hintArray.length - 1}}】{{hintArray[hintArray.length - 1]}}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
  import {canvasQrBase} from "../../public/template/canvasQr/canvasQrBase";
  import {URL,PATH_LOGO_STORE,PROJECT_NAME} from "../../../config";
  import {drawBeforeImages} from "../../../assets/js/downImage";

  let defaultBrief = "发现一个好店，推荐给你呀";
  function returnBaseUserInfo(){
    return {
      userLogo: PATH_LOGO_STORE,
      userName: "用户未登录",
      inviteCode: defaultBrief,
    }
  }
  export default {
    name: "canvasQrStore",
    mixins:[canvasQrBase],
    props:{
      title: {
        type: String,
        default: '店铺推广码'
      },
      userInfo: {
        type: Object,
        default:function(){
          return returnBaseUserInfo();
        }
      },
    },
    data(){
      return {

      }
    },
    created(){},
    mounted() {},
    methods:{
      /**
       * 判断绘制条件
       */
      judgeDrawPrecondition(){
        this.pushHint('判断绘制前提条件');
        if(!this.$store.state.token){
          return this.pushHint('没有登录 error');
        }
        if(typeof this.userInfo != 'object'){
          return this.pushHint('没有店铺数据 error');
        }
        if(!this.userInfo.userName){
          return this.pushHint('没有店铺名称 error');
        }
        // if(!this.userInfo.userLogo){
        //   return this.pushHint('没有店铺头像 error');
        // }
        this.getWxQr().then(res=>{
          return this.drawBeforeImagesQr(res);
        }).then(res=>{
          return this.drawBeforeImagesUser();
        }).then(res=>{
          this.$nextTick(()=>{
            this.createCanvas();
          });
        });
      },
      /**
       * 获取店铺小程序码
       */
      getWxQr(){
        this.pushHint('获取 小程序码');
        return this.axios.get(URL.suppSupplierStore.getStoreWxaCode, {
          timeout:6e3,
        }).then(res=>{
          if(res.code === 0 && res.data.url) {
            return Promise.resolve(res.data.url);
          }else{
            return Promise.reject(res);
          }
        }).catch(res=>{
          this.pushHint('获取 小程序码 error ' + res.message||'');
          return Promise.reject(res);
        })
      },
      /**
       * 下载图片
       */
      drawBeforeImagesUser(src = this.userInfo.userLogo){
        this.pushHint('下载 用户头像 本地资源');
        if(!src){
          let defaultUser = returnBaseUserInfo();
          src = defaultUser.userLogo;
        }
        src = this.ossImagesMFit(src,100);
        return drawBeforeImages(src).catch(res=>{
          this.pushHint('下载 用户头像 error ' + res.message||'');
        }).then(res=>{
          this.filePaths.user = res;
          return Promise.resolve();
        });
      },
      drawBeforeImagesQr(src){
        this.pushHint('下载 小程序码 本地资源',src);
        src = this.ossImagesMFit(src,400);
        return drawBeforeImages(src).catch(res=>{
          this.pushHint('下载 小程序码 error ' + res.message||'');
        }).then(res=>{
          this.filePaths.qr = res;
          return Promise.resolve();
        });
      },
      /**
       * 绘制过程
       * @param ctx
       */
      canvasDrawStart(ctx){
        this.pushHint('绘制 开始');
        this.drawBg(ctx);
        this.drawTitle(ctx);
        this.drawQr(ctx);
        this.drawUser(ctx);
        this.pushHint('绘制 success');
      },
      drawTitle(ctx){
        this.pushHint('绘制 标题');
        let x = this.size.width/2;
        let y = 130;
        ctx.font = '48px PingFangSC-Semibold, PingFang SC';
        ctx.fillStyle = '#666666';
        ctx.textAlign = 'center';
        let textArray = [
          PROJECT_NAME + '商城',
          '快一点/多一点/好一点',
        ];
        for(let item of textArray){
          ctx.fillText(item,x,y);
          y += 48;
          y += 30;
        }
        ctx.textAlign = 'left';
        ctx.restore();
      },
      drawQr(ctx){
        this.pushHint('绘制 小程序码');
        let qrWidth = 366;
        let x = (this.size.width - qrWidth)/2;
        let y = 320;
        if(this.filePaths.qr) ctx.drawImage(this.filePaths.qr, x, y, qrWidth, qrWidth);
        ctx.restore();
      },
      drawUser(ctx){
        this.pushHint('绘制 店铺头像/名称/简介');
        let x = 100;
        let y = 735;
        let portraitWidth = 80;
        let user = this.filePaths.user;
        let nickName = this.userInfo.userName;
        let inviteCode = this.userInfo.inviteCode||defaultBrief;
        ctx.save();
        ctx.beginPath();
        ctx.arc(x +  portraitWidth/ 2 , y + portraitWidth / 2, portraitWidth / 2, 0, Math.PI * 2, false);
        ctx.clip();
        if(user) {
          ctx.drawImage(user, x, y, portraitWidth, portraitWidth);
        }
        ctx.restore();
        //名称
        x += portraitWidth;
        x += 20;
        y += 28;
        ctx.font = 'bold 28px PingFangSC-Semibold, PingFang SC';
        ctx.fillStyle = '#333';
        ctx.fillText(nickName,x ,y);
        // 邀请码
        y += 20;
        y += 24;
        ctx.font = '24px PingFangSC-Semibold, PingFang SC';
        ctx.fillStyle ='#666666';
        ctx.fillText(inviteCode,x ,y);
        x = 0;
        y = 0;
        ctx.restore();
      },

      /**
       * 下载图片生成的名字
       * @returns {string}
       */
      setDownImageName(){
        return this.userInfo.userName;
      },
    },
    computed:{},
    watch:{},
  }
</script>


<style scoped lang="less">
  @import "../../public/template/canvasQr/canvasQrBase";
</style>
