// 微服务路径前缀

export const MICRO_SERVICE_PATH = {
  kernel: '/kernel',
  minor: '/minor',
  auth: '/auth',
  other: '/other',
  back: '/back',
  open: '/open',
  farm: '/farm-api/web'
}
