import * as urlChildren from './url.supplier.js'
import { deepsAssign } from '../assets/js/utils'
import { MICRO_SERVICE_PATH } from './url.base'

let after = urlChildren;

// api request url
export const URL = deepsAssign({
  region:{ // 地址库
    list: MICRO_SERVICE_PATH.open + '/region/list', // 获取地址库
    provinceCity: MICRO_SERVICE_PATH.open + '/region/provinceCity', // 省市地址字典
    provinceCityCounty: MICRO_SERVICE_PATH.open + '/region/provinceCityCounty', // 全部省市地址字典信息
  },
  expressCompany:{//快递公司
    list: MICRO_SERVICE_PATH.open + '/base/getExpressCompany', // 获取地址库
  },
},after.URL);

export const URL_MINOR = deepsAssign({
  // 文件或图片上传
  upload:{
    uploadFile: MICRO_SERVICE_PATH.open + '/upLoadToOssWithSign', // 上传单个文件
    uploadImage: MICRO_SERVICE_PATH.open + '/upLoadToOssWithSign', // 上传单张图片
    ueUpload: MICRO_SERVICE_PATH.open + '/upLoadToOssWithSign', // 百度编辑器上传图片
  },
  // 公共文件接口
  commonFile: {
    createExcel: MICRO_SERVICE_PATH.back + '/export/createExcel', // 创建Excel报表任务
    deleteSystemExcel: MICRO_SERVICE_PATH.back + '/export/deleteSystemExcel', // 删除excel任务
    getSystemExcel: MICRO_SERVICE_PATH.back + '/export/getSystemExcel', // 获取任务详情
    getSystemExcelList: MICRO_SERVICE_PATH.back + '/export/getSystemExcelList', // excel下载任务列表
    type: MICRO_SERVICE_PATH.back + '/export/type', // 导出类型字典
    uploadOne: '/open/upLoadToOssWithSign', // 上传单个文件,可以是图片,音乐,视频
    getVodSignature: '/open/getVodSignature', // 上传单个文件,可以是图片,音乐,视频
  },
},after.URL_MINOR)

export const URL_AUTH = deepsAssign({ // 统一登录
  loginCode: '/code', // 获取登录验证码
},after.URL_AUTH)
