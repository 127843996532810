import Vue from 'vue'
import Vuex from 'vuex'
import {isTest,systemName} from '../config/index'
import homeRouterTabs from './homeRouterTabs'
import excel from './excel'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isTest,
    systemName:systemName,
    token:localStorage.getItem('token') || '',
    userInfo:(function(){
      let str = localStorage.getItem('user_info');
      
      try {
        return JSON.parse(str) || {}
      }catch (e) {
        return {}
      }
    })(),
    minCostPercent:2,// 最小平台扣点百分比
    showCostAndPurchase:true, // 是否展示扣点和供货价
    minB2bCostPercent:2, // 批发扣点百分比
  },
  mutations: {
    setSystemName(state,value){
      state.systemName = value;
    },
    setToken(state,value){
      localStorage.setItem('token',value);
      state.token = value;
    },
    setUserInfo(state,value){
      localStorage.setItem('user_info',JSON.stringify(value));
      state.userInfo = value;
    },
    setGoodsEditConfig(state,value){
      state.minCostPercent = value.minCostPercent;
      state.showCostAndPurchase = value.showCostAndPurchase;
      state.minB2bCostPercent = value.minB2bCostPercent
    },
    setMinCostPercent(state,value){
      state.minCostPercent = value;
    },
    setShowCostAndPurchase(state,value){
      state.showCostAndPurchase = value;
    },
  },
  actions: {
  },
  modules: {
    homeRouterTabs,
    excel,
  }
})
