const ROUTER_LIST = [
    /* 品牌管理 */
    {
        path: "/supplierBrand",
        name: "supplierBrand",
        meta: { title: "品牌与类目" },
        component: () => import("../views/supplier/brand/index.vue")
    },
    /* 运费模板 */
    {
        path: "/supplierPostage",
        name: "supplierPostage",
        meta: { title: "运费模板列表" },
        component: () => import("../views/supplier/postage/list.vue")
    },
    {
        path: "/newSupplierPostage",
        name: "newSupplierPostage",
        meta: { title: "新运费模板列表" },
        component: () => import("../views/supplier/postage/newList.vue")
    },
    {
        path: "/cmsConfig",
        name: "cmsConfig",
        meta: { title: "店铺装修" },
        component: () => import("../views/supplier/store/cmsConfig.vue")
    },
    /* 商品管理 */
    {
        path: "/supplierGoods",
        name: "supplierGoods",
        meta: { title: "商品列表" },
        component: () => import("../views/supplier/goods/list.vue")
    },
    {
        path: "/supplierStockList",
        name: "supplierStockList",
        meta: { title: "库存管理" },
        component: () => import("../views/supplier/goods/stockList.vue")
    },
    {
        path: "/supplierDeletedGoods",
        name: "supplierDeletedGoods",
        meta: { title: "已删除商品" },
        component: () => import("../views/supplier/goods/deleteList.vue")
    },
    {
        path: "/supplierGoodsDraft",
        name: "supplierGoodsDraft",
        meta: { title: "草稿箱" },
        component: () => import("../views/supplier/goods/draft.vue")
    },
    {
        path: "/supplierGoodsReview",
        name: "supplierGoodsReview",
        meta: { title: "审核商品" },
        component: () => import("../views/supplier/goods/review.vue")
    },
    {
        path: "/comment",
        name: "comment",
        meta: { title: "评价管理" },
        component: () => import("../views/supplier/goods/comment.vue")
    },
    // 农场管理
    // {
    //   path: '/farmLand',
    //   name: 'farmLand',
    //   meta: { title: '土地列表' },
    //   component: () => import('../views/supplier/farm/land.vue')
    // },
    // {
    //   path: '/farmSeed',
    //   name: 'farmSeed',
    //   meta: { title: '种子列表' },
    //   component: () => import('../views/supplier/farm/seed.vue')
    // },
    {
        path: "/seedList",
        name: "seedList",
        meta: { title: "种子列表" },
        component: () => import("../views/supplier/farm/seedList.vue")
    },
    {
        path: "/farmConfig",
        name: "farmConfig",
        meta: { title: "农场管理员" },
        component: () => import("../views/supplier/farm/farmConfig.vue")
    },
    {
        path: "/landAuthList",
        name: "landAuthList",
        meta: { title: "土地授权列表" },
        component: () => import("../views/supplier/farm/landAuthList.vue")
    },
    {
        path: "/rentOrder",
        name: "rentOrder",
        meta: { title: "租赁订单" },
        component: () => import("../views/supplier/farm/rentOrder.vue")
    },
    {
        path: "/taskManage",
        name: "taskManage",
        meta: { title: "任务大厅管理" },
        component: () => import("../views/supplier/farm/taskManage.vue")
    },
    {
        path: "/warehouse",
        name: "warehouse",
        meta: { title: "仓库记录" },
        component: () => import("../views/supplier/farm/warehouse.vue")
    },
    {
        path: "/packageManage",
        name: "packageManage",
        meta: { title: "租赁套餐管理" },
        component: () => import("../views/supplier/farm/packageManage.vue")
    },
    {
        path: "/serviceConfig",
        name: "serviceConfig",
        meta: { title: "服务类型配置" },
        component: () => import("../views/supplier/farm/serviceConfig.vue")
    },
    {
        path: "/landConfig",
        name: "landConfig",
        meta: { title: "土地认租认种" },
        component: () => import("../views/supplier/farm/landConfig.vue")
    },
    // {
    //   path: '/farmRent',
    //   name: 'farmRent',
    //   meta: { title: '土地租用列表' },
    //   component: () => import('../views/supplier/farm/rent.vue')
    // },
    {
        path: "/farmRecord",
        name: "farmRecord",
        meta: { title: "生活农场记录" },
        component: () => import("../views/supplier/farm/record.vue")
    },
    // {
    //   path: '/todayPresell',
    //   name: 'todayPresell',
    //   meta: { title: '今日预告商品' },
    //   component: () => import('../views/supplier/goods/today-presell.vue')
    // },
    {
        path: "/supplierStoreInfo",
        name: "supplierStoreInfo",
        meta: { title: "基础信息" },
        component: () => import("../views/supplier/store/baseInfo.vue")
    },
    {
        path: "/bannerList",
        name: "bannerList",
        meta: { title: "banner配置" },
        component: () => import("../views/supplier/store/bannerList.vue")
    },
    {
        path: "/refundAddressList",
        name: "refundAddressList",
        meta: { title: "退货地址" },
        component: () => import("../views/supplier/store/refundAddressList.vue")
    },
    // 普通订单详情
    {
        path: "/wholesaleOrderDetail",
        name: "wholesaleOrderDetail",
        meta: { title: "订单详情" },
        component: () =>
            import("../views/supplier/wholesaleOrder/orderDetail.vue")
    },
    // 批发订单详情
    {
        path: "/orderDetail",
        name: "orderDetail",
        meta: { title: "订单详情" },
        component: () => import("../views/supplier/order/orderDetail.vue")
    },
    // 普通订单列表
    {
        path: "/supplierOrderList",
        name: "supplierOrderList",
        meta: { title: "普通订单列表" },
        component: () => import("../views/supplier/order/list.vue")
    },
    // 批发订单列表
    // {
    //   path: '/supplierWholesaleOrderList',
    //   name: 'supplierWholesaleOrderList',
    //   meta: { title: '批发订单列表' },
    //   component: () => import('../views/supplier/wholesaleOrder/list.vue')
    // },
    // 普通待发货订单
    {
        path: "/supplierOrderWaitSendList",
        name: "supplierOrderWaitSendList",
        meta: { title: "待发货订单" },
        component: () => import("../views/supplier/order/waitSendList.vue")
    },
    // 批发待发货订单
    // {
    //   path: '/supplierWholesaleOrderWaitSendList',
    //   name: 'supplierWholesaleOrderWaitSendList',
    //   meta: { title: '待发货订单' },
    //   component: () => import('../views/supplier/wholesaleOrder/waitSendList.vue')
    // },
    {
        path: "/storeCategory",
        name: "storeCategory",
        meta: { title: "店铺商品分类" },
        component: () => import("../views/supplier/store/cateList.vue")
    },
    // 普通商品售后
    {
        path: "/supplierRefundOrderList",
        name: "supplierRefundOrderList",
        meta: { title: "售后订单列表" },
        component: () => import("../views/supplier/order/refundList.vue")
    },
    // 批发商品售后
    // {
    //   path: '/supplierWholesaleRefundOrderList',
    //   name: 'supplierWholesaleRefundOrderList',
    //   meta: { title: '售后订单列表' },
    //   component: () => import('../views/supplier/wholesaleOrder/refundList.vue')
    // },
    {
        path: "/orderRefundDetail",
        name: "orderRefundDetail",
        meta: { title: "订单售后详情" },
        component: () => import("../views/supplier/order/orderRefundDetail.vue")
    },
    {
        path: "/approvedList",
        name: "approvedList",
        meta: { title: "评价置顶" },
        component: () => import("../views/supplier/order/approvedList.vue")
    },

    {
        path: "/supplierEditGoods",
        name: "supplierEditGoods",
        meta: { title: "编辑商品", keepAlive: true },
        component: () => import("../views/supplier/goods/edit.vue")
    },
    {
        path: "/getAssetCenterInfo",
        name: "getAssetCenterInfo",
        meta: { title: "账户资产" },
        component: () =>
            import("../views/supplier/wallet/getAssetCenterInfo.vue")
    },
    {
        path: "/getSupplierBills",
        name: "getSupplierBills",
        meta: { title: "收支明细" },
        component: () => import("../views/supplier/wallet/getSupplierBills.vue")
    },
    {
        path: "/getSupplierSettleList",
        name: "getSupplierSettleList",
        meta: { title: "订单流水" },
        component: () =>
            import("../views/supplier/wallet/getSupplierSettleList.vue")
    },
    {
        path: "/moneyFlow",
        name: "moneyFlow",
        meta: { title: "收入流水" },
        component: () => import("../views/supplier/wallet/moneyFlow.vue")
    },
    {
        path: "/promotionCost",
        name: "promotionCost",
        meta: { title: "推广费用明细" },
        component: () => import("../views/supplier/wallet/promotionCost.vue")
    },
    {
        path: "/supplierSettleDetail",
        name: "supplierSettleDetail",
        meta: { title: "订单流水详情" },
        component: () =>
            import("../views/supplier/wallet/supplierSettleDetail.vue")
    },
    {
        path: "/getSupplierCommission",
        name: "getSupplierCommission",
        meta: { title: "佣金服务费明细" },
        component: () =>
            import("../views/supplier/wallet/getSupplierCommission.vue")
    },
    {
        path: "/applyOpenStore",
        name: "applyOpenStore",
        meta: { title: "提交资料-申请入驻" },
        component: () => import("../views/supplier/apply/applyOpenStore.vue")
    },
    {
        path: "/secondsKill",
        name: "secondsKill",
        meta: { title: "秒杀活动列表" },
        component: () => import("../views/supplier/marketing/secondsKill.vue")
    },
    {
        path: "/secondsKillEdit",
        name: "secondsKillEdit",
        meta: { title: "编辑秒杀活动" },
        component: () =>
            import("../views/supplier/marketing/secondsKillEdit.vue")
    },
    {
        path: "/fansDataProfile",
        name: "fansDataProfile",
        meta: { title: "数据管理-粉丝概况" },
        component: () => import("../views/supplier/data/fansDataProfile.vue")
    },
    {
        path: "/tradeDataProfile",
        name: "tradeDataProfile",
        meta: { title: "数据管理-交易" },
        component: () => import("../views/supplier/data/tradeDataProfile.vue")
    },
    {
        path: "/suppGoodsProfile",
        name: "suppGoodsProfile",
        meta: { title: "数据管理-商品分析" },
        component: () => import("../views/supplier/data/goodsDataProfile.vue")
    },
    {
        path: "/attentionMember",
        name: "attentionMember",
        meta: { title: "会员中心-关注会员" },
        component: () => import("../views/supplier/member/attentionMember.vue")
    },
    {
        path: "/bindingMember",
        name: "bindingMember",
        meta: { title: "绑定会员-商品分析" },
        component: () => import("../views/supplier/member/bindingMember.vue")
    },
    {
        path: "/platformNotificationDetails",
        name: "platformNotificationDetails",
        meta: { title: "平台通知" },
        component: () =>
            import("../views/supplier/inform/platformNotificationDetails.vue")
    },
    {
        path: "/promotionGoods",
        name: "promotionGoods",
        meta: { title: "推广带货" },
        component: () =>
            import("../views/supplier/marketing/promotionGoods.vue")
    },
    {
        path: "/promotionSet",
        name: "promotionSet",
        meta: { title: "推广设置" },
        component: () => import("../views/supplier/marketing/promotionSet.vue")
    }
];

export default ROUTER_LIST;
