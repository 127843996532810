// request base url
let axiosOutTime = 10000
let baseURL = process.env.VUE_APP_baseURL;

// use mock.js
let useMock = !!process.env.VUE_APP_MOCK

// open test data push
let isTest = process.env.NODE_ENV === 'development'

export const PROJECT_NAME = '村知了'
export const SYSTEM_NAME = `${PROJECT_NAME}-商家后台`
export const systemName = `${PROJECT_NAME}-商家后台`

export const PATH_IMAGES = 'http://imgs.cunzhiliao.com/applet-fzg'
export const PATH_FILES = 'https://imgs.cunzhiliao.com/applet-fzg'
export const PATH_LOGO = `${PATH_IMAGES}/logo/logo.png`
export const PATH_LOGO_USER = `${PATH_IMAGES}/logo/logo_user.png`
export const PATH_LOGO_STORE = `${PATH_IMAGES}/logo/logo_store.png`

// alter
if (isTest) {
  baseURL = '/api';
  // 请求超时时间
  axiosOutTime = 10000
  // 是开发环境
  // 控制显示“填充测试数据”按钮、
  isTest = true
  // 开启mock
  useMock = !0
}

export { URL, URL_MINOR, URL_AUTH } from './url'

export { baseURL, axiosOutTime, useMock, isTest, }
