import {URL} from "../../config";
import axios from '../../axios/index'
import store from '../../store';

export function requestUserInfo(){
  return axios.get(URL.supplier.simpleInfo).then(res=>{
    if(!res.data.userName && res.data.name) res.data.userName = res.data.name;
    if(!res.data.userLogo && res.data.logo) res.data.userLogo = res.data.logo;
    store.commit('setUserInfo',res.data);
    return Promise.resolve(res);
  })
}
