import Vue from 'vue'
import axios from 'axios'
import router from '../router/index'
import { baseURL,axiosOutTime ,URL_AUTH} from '../config/index'
import store from '../store/index'
axios.defaults.timeout = axiosOutTime
axios.defaults.baseURL = baseURL // 请求头公共链接部分
let LOGIN_URL_ARRAY = [
  URL_AUTH.loginCode,
  URL_AUTH.loginSupplier,
];
function toLoginBefore(){
  store.commit('setToken','');
}
// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 动态请求IP
  // if(process.env.NODE_ENV !== 'development') config.baseURL = config.url.indexOf('/farm-api/web') == -1 ? baseURL : '';

  let isLogin = LOGIN_URL_ARRAY.includes(config.url);
  // 在发送请求之前做些什么
  config.headers['Access-Control-Allow-Origin'] = '*'
  if (!config.headers.token && !isLogin) {
    let token = store.state.token
    if(token) {
      config.headers.token = token
    } else if(config.needLogin) {
      setTimeout(res=>{
        router.push('/login')
      },5e2);
      return Promise.reject(new Error("请先登录"))
    }
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

function joinRequestTile (response) {
  return ''
  // return response.config.url || ''
}

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  const name = joinRequestTile(response)
  // 对响应数据做点什么
  if (response.status === 200 || response.status === 304) {
    if (typeof response.data === 'string') {
      // 后台重定向导致返回了接口文档页面SwaggerUI.html
      return Promise.reject(new Error(`${name} swagger-ui 404`))
    }
    if(response.data.code === 0 || response.data.code === '0'){
      if(response.config.msgSuccess){
        Vue.prototype.$message.success({
          message: typeof response.config.msgSuccess === 'string' ? response.config.msgSuccess : response.data.msg
        })
      }
      return response.data
    }
    let msg = `${name}`;
    if (response.data.code === 555) {
      // 没有权限
      msg += '没有权限'
    }else if (response.data.code === 666) {
      // 登录超时
      // msg += '登录超时';
      toLoginBefore();
      router.push('/login')
    }else {
      msg += `${response.data.msg}`
    }
    if(typeof response.config.msgError === 'undefined' || response.config.msgError){
      if(response.data.code != 666){
        Vue.prototype.$message.error({
          message: msg
        });
      }
    }
    return Promise.reject(msg)
  }
  return Promise.reject(new Error(`${name} ${response.status}`))
}, function (error) {
  const name = joinRequestTile(error)
  if (typeof error.response === 'object' && typeof error.response.data === 'object') {
    let code = error.response.data.code
    if(code === 666){
      // 请求超时
      // Vue.prototype.$message.error({
      //   message: `${name} 登录超时`
      // });
      toLoginBefore();
      setTimeout(res => {
        router.push('/login')
      },5e2);
    }else{
      // 提示后台msg
      Vue.prototype.$message.error({
        message: `${name} ${error.response.data.msg} ${error.response.status}`
      })
    }
  }else if (/Network Error/ig.test(error.message)) {
    // 网络错误
    Vue.prototype.$message.error({
      message: `${name} 网络错误`
    })
  } else if (/timeout/ig.test(error.message)) {
    // 请求超时
    Vue.prototype.$message.error({
      message: `${name} 请求超时`
    })
  }else {
    Vue.prototype.$message.error({
      message: `${name} ${error.message}`
    })
  }
  // 对响应错误做点什么
  return Promise.reject(error)
});

export default axios
