import store from "@/store/index";
const siteType = store.state.userInfo.siteType;
const menuList = [
    {
        name: "首页",
        id: "",
        icon: "",
        show: true,
        url: "/index"
    },
    // {
    //   name: '申请入驻',
    //   id: '',
    //   icon: 'el-icon-s-finance',
    //   children: [
    //     {
    //       name: '提交资料',
    //       id: '',
    //       url: '/applyOpenStore',
    //       icon: '',
    //     },
    //   ],
    // },
    {
        name: "店铺管理",
        id: "",
        icon: "el-icon-s-shop",
        show: true,
        children: [
            {
                name: "基础信息",
                id: "",
                url: "/supplierStoreInfo",
                icon: "",
                show: true
            },
            {
                name: "退货地址",
                id: "",
                url: "/refundAddressList",
                icon: "",
                show: true
            },
            // {
            //   name: '店铺分类',
            //   id: '',
            //   url: '/storeCategory',
            //   icon: '',
            // },
            // {
            //   name: '旧运费模板',
            //   id: '',
            //   url: '/supplierPostage',
            //   icon: '',
            // },
            {
                name: "运费模板",
                id: "",
                url: "/newSupplierPostage",
                icon: "",
                show: true
            },
            {
                name: "banner配置",
                id: "",
                url: "/bannerList",
                icon: "",
                show: true
            }
            // {
            //   name: '品牌与类目',
            //   id: '',
            //   url: '/supplierBrand',
            //   icon: '',
            // // },
            // {
            //   name: '店铺装修',
            //   id: '',
            //   url: '/cmsConfig',
            //   icon: '',
            // },
        ]
    },
    {
        name: "商品管理",
        id: "",
        show: true,
        icon: "el-icon-s-goods",
        children: [
            {
                name: "新增商品",
                id: "",
                url: "/supplierEditGoods",
                icon: "",
                show: true
            },
            {
                name: "商品列表",
                id: "",
                url: "/supplierGoods",
                icon: "",
                show: true
            },
            // {
            //   name: '今日预告商品',
            //   id: '',
            //   url: '/todayPresell',
            //   icon: '',
            // },
            {
                name: "库存管理",
                id: "",
                url: "/supplierStockList",
                icon: "",
                show: true
            },
            {
                name: "已删除商品",
                id: "",
                url: "/supplierDeletedGoods",
                icon: "",
                show: true
            },

            {
                name: "草稿箱",
                id: "",
                url: "/supplierGoodsDraft",
                icon: "",
                show: true
            },
            {
                name: "审核商品",
                id: "",
                url: "/supplierGoodsReview",
                icon: "",
                show: true
            },
            {
                name: "评价管理",
                id: "",
                url: "/comment",
                icon: "",
                show: true
            }
        ]
    },
    {
        name: "农场管理",
        id: "",
        show: [3].includes(siteType),
        icon: "el-icon-s-goods",
        children: [
            {
                name: "土地认租认种",
                id: "",
                url: "/landConfig",
                icon: "",
                show: [3].includes(siteType)
            },
            {
                name: "种子列表",
                id: "",
                url: "/seedList",
                icon: "",
                show: [3].includes(siteType)
            },
            {
                name: "农场管理员",
                id: "",
                url: "/farmConfig",
                icon: "",
                show: [3].includes(siteType)
            },
            {
                name: "土地授权列表",
                id: "",
                url: "/landAuthList",
                icon: "",
                show: [3].includes(siteType)
            },
            {
                name: "租赁订单",
                id: "",
                url: "/rentOrder",
                icon: "",
                show: [3].includes(siteType)
            },
            {
                name: "任务大厅管理",
                id: "",
                url: "/taskManage",
                icon: "",
                show: [3].includes(siteType)
            },
            {
                name: "仓库记录",
                id: "",
                url: "/warehouse",
                icon: "",
                show: [3].includes(siteType)
            },
            {
                name: "租赁套餐管理",
                id: "",
                url: "/packageManage",
                icon: "",
                show: [3].includes(siteType)
            },
            {
                name: "服务类型配置",
                id: "",
                url: "/serviceConfig",
                icon: "",
                show: [3].includes(siteType)
            },
            // {
            //   name: '土地列表',
            //   id: '',
            //   url: '/farmLand',
            //   icon: '',
            //   show: [3].includes(siteType),
            // },
            // {
            //   name: '种子列表',
            //   id: '',
            //   url: '/farmSeed',
            //   icon: '',
            //   show: [3].includes(siteType),
            // },
            // {
            //   name: '土地租用列表',
            //   id: '',
            //   url: '/farmRent',
            //   icon: '',
            //   show: [3].includes(siteType),
            // },
            {
                name: "生活农场记录",
                id: "",
                url: "/farmRecord",
                icon: "",
                show: [3].includes(siteType)
            }
        ]
    },
    {
        name: "订单管理",
        id: "",
        show: true,
        icon: "el-icon-s-order",
        children: [
            {
                name: "订单列表",
                id: "",
                url: "/supplierOrderList",
                icon: "",
                show: true
            },
            {
                name: "待发货订单",
                id: "",
                url: "/supplierOrderWaitSendList",
                icon: "",
                show: true
            },
            {
                name: "售后订单",
                id: "",
                url: "/supplierRefundOrderList",
                icon: "",
                show: true
            }
            // {
            //   name: '评价置顶',
            //   id: '',
            //   url: '/approvedList',
            //   icon: '',
            // },
        ]
    },
    // {
    //   name: '批发订单管理',
    //   id: '',
    //   icon: 'el-icon-s-order',
    //   children: [
    //     {
    //       name: '订单列表',
    //       id: '',
    //       url: '/supplierWholesaleOrderList',
    //       icon: '',
    //     },
    //     {
    //       name: '待发货订单',
    //       id: '',
    //       url: '/supplierWholesaleOrderWaitSendList',
    //       icon: '',
    //     },
    //     {
    //       name: '售后订单',
    //       id: '',
    //       url: '/supplierWholesaleRefundOrderList',
    //       icon: '',
    //     },
    //   ]
    // },
    // {
    //   name: '营销活动',
    //   id: '',
    //   icon: 'el-icon-s-finance',
    //   children: [
    //     {
    //       name: '限时秒杀',
    //       id: '',
    //       url: '/secondsKill',
    //       icon: '',
    //     },
    //     {
    //       name: '推广带货',
    //       id: '',
    //       url: '/promotionGoods',
    //       icon: '',
    //     },
    //   ]
    // },
    {
        name: "资产中心",
        id: "",
        icon: "el-icon-s-finance",
        show: true,
        children: [
            {
                name: "账户资产",
                id: "",
                url: "/getAssetCenterInfo",
                icon: "",
                show: true
            },
            {
                name: "收支明细",
                id: "",
                url: "/getSupplierBills",
                icon: "",
                show: true
            },
            {
                name: "订单流水",
                id: "",
                url: "/getSupplierSettleList",
                icon: "",
                show: true
            },
            {
                name: "收入流水",
                id: "",
                url: "/moneyFlow",
                icon: "",
                show: true
            }
            // {
            //   name: '佣金服务费明细',
            //   id: '',
            //   url: '/getSupplierCommission',
            //   icon: '',
            // },
            // {
            //   name: '推广费用明细',
            //   id: '',
            //   url: '/promotionCost',
            //   icon: '',
            // },
        ]
    },
    // {
    //   name:'会员中心',
    //   id: '',
    //   icon:'el-icon-s-custom',
    //   children:[
    //     {
    //       name: '关注会员',
    //       id: '',
    //       url: '/attentionMember'
    //     },{
    //       name: '绑定会员',
    //       id: '',
    //       url: '/bindingMember'
    //     }
    //   ]
    // },
    {
        name: "数据管理",
        id: "",
        show: true,
        icon: "el-icon-s-data",
        children: [
            {
                name: "粉丝概况",
                id: "",
                url: "/fansDataProfile",
                icon: "",
                show: true
            },
            {
                name: "交易分析",
                id: "",
                url: "/tradeDataProfile",
                icon: "",
                show: true
            },
            {
                name: "商品分析",
                id: "",
                url: "/suppGoodsProfile",
                icon: "",
                show: true
            }
        ]
    }
];
export default menuList;
