import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/defined.less'
import './assets/css/reset.less'
import './assets/css/element-ui-reset.less'
import './assets/css/main.less'
import VueAxios from 'vue-axios'
import axios from './axios/index'
import qs from 'qs';
import {VueJsonp}  from 'vue-jsonp'
import VueQuillEditor from 'vue-quill-editor';
import dayjs from 'dayjs';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '../src/assets/theme/index.css'

import tableHeightAuto from './components/public/tableHeightAuto/tableHeightAuto'
import {isTest} from './config'

Vue.component('tableHeightAuto',tableHeightAuto);
Vue.use(VueQuillEditor);

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.prototype.$dayjs = dayjs;

Vue.prototype.qs = qs;
Vue.use(elementUI)
Vue.use(VueAxios, axios)
Vue.use(VueJsonp)

Vue.config.devtools = isTest;
Vue.config.productionTip = isTest;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
