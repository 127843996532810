<template>
  <div class="export-excel-item"
       v-loading="loading"
       v-if="dataMap.name && !dataMap.hidden">
    <a class="title text-line-hide" :title="dataMap.name">{{dataMap.name}}</a>
    <div class="file-type" v-if="dataMap.contentType">{{dataMap.contentType}}</div>
    <div class="flex-item margin-top-10">
      <div class="content brief">
        <template v-if="dataMap.params && dataMap.status">
          {{status[dataMap.status]}}
        </template>
        <template v-else-if="dataMap.lastAccessTime">
          最近：{{dataMap.lastAccessTime_text}}
        </template>
      </div>
      <el-tooltip class="item" effect="dark" content="下载" placement="top-end">
        <el-button type="primary"
                   size="mini"
                   icon="el-icon-download"
                   circle
                   @click="download(dataMap.url)"
                   v-if="dataMap.url"></el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="删除记录" placement="top-end">
        <el-button type="danger"
                   size="mini"
                   icon="el-icon-delete"
                   circle
                   @click="deleteItem"
                   v-if="dataMap.url"></el-button>
      </el-tooltip>

    </div>
  </div>
</template>

<script>
  import {isEmpty,parseTimeObject} from '../../../assets/js/utils'
  import {URL_MINOR} from '../../../config'
  import MD5 from 'crypto-js/md5'

  export default {
    name: 'downloadExcelItem',
    props:{
      data:{
        type:Object,
      }
    },
    data(){
      return {
        loading:false,
        dataMap:{},
        timeOut:null,
        status:{
          1:"任务创建中",
          2:"任务完成",
          3:"任务创建失败",
        }
      }
    },
    created(){
      if(!isEmpty(this.data)){
        this.dataMap = this.data;
        this.disposeMap();
      }
    },
    destroyed(){
      this.clearWatch();
    },
    methods:{
      disposeMap(){
        if(this.dataMap.params){
          this.createdTask();
        }
        if(this.dataMap.status === 2){
          parseTimeObject(this.dataMap,['lastAccessTime'])
        }
        if(this.dataMap.originName){
          this.dataMap.name =  this.dataMap.originName
        }
      },
      download(url){
        window.location.protocol
        let newUrl = url
        if (window.location.protocol == "https:") {
          newUrl = url.replace('http', 'https');
        }
        try {
          window.location.href = newUrl;
        }catch (e) {
          window.location = newUrl;
        }
      },
      deleteItem(){
        let data = {
          id:this.dataMap.id,
          userType:3,
        }
        this.loading = true;
        this.axios.post(URL_MINOR.commonFile.deleteSystemExcel,data).then(res=>{
          this.loading = false;
          this.dataMap.hidden = true;
        }).catch(res=>{
          this.loading = false;
        })
      },
      createdTask(params = this.dataMap.params){
        this.loading = true;
        this.axios.post(URL_MINOR.commonFile.createExcel,params).then(res=>{
          this.loading = false;
          if(res.data){
            this.dataMap.createdId = res.data;
            this.dataMap.status = 1;
            this.watchTaskProgress();
          }
        }).catch(res=>{
          this.loading = false;
        })
      },
      watchTaskProgress(id = this.dataMap.createdId){
        if(!id) return false;
        this.loading = true;
        this.timeOut = setInterval(()=>{
          this.getTaskProgress();
        },1e3);
      },
      getTaskProgress(id = this.dataMap.createdId){
        if(!id) {
          this.clearWatch();
          return false
        }
        let data = {
          id,
          userType:3,
        }
        this.axios.get(URL_MINOR.commonFile.getSystemExcel, {params: data}).then(res => {
          if(res.data.status !== this.dataMap.status){
            this.dataMap = res.data;
            this.clearWatch();
            this.disposeMap();
            if(res.data.status === 2){
              this.download(res.data.url);
            }
          }
        }).catch(res=>{

        }).finally(res=>{

        })
      },
      clearWatch(){
        if(this.timeOut){
          clearInterval(this.timeOut);
          this.timeOut = null;
          this.loading = false;
        }
      },
    },
    computed:{
      objectKey(){
        let key = this.dataMap.objectKey;
        if(!key) return '';
        return MD5(key).toString()
      }
    }
  }
</script>

<style scoped lang="less">
  @import (reference) "../../../assets/css/data";
  .export-excel-item{
    border-radius: 6px;
    overflow: hidden;
    position: relative;
    display: block;
    box-shadow: 0 0 4px #e2e2e2;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: @margin-primary;
    transition: 0.4s ease;
    .title{
      font-size: @font-size-main-auxiliary;
    }
    .brief{
      color: @color-info;
      font-size: 12px;
      word-break: break-all;
    }
    &:hover{
      box-shadow: 0 0 16px #ccc;
    }
  }
  .file-type{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 0 4px;
    background-color: #f2f2f2;
    color: @color-info;
  }
</style>
